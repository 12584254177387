/* eslint-disable no-unused-vars */
import { collection, getDoc, getDocs, query, where } from "firebase/firestore";

import { firestore } from "../../../services/Firebase";

const CollectionName = "depositions";

export const getAllDepositions = async () => {
    try {
        const collectionRef = collection(firestore, CollectionName);
        const querySnapshot = await getDocs(collectionRef);

        const dataDeposition = [];

        querySnapshot.forEach((dataDocument) => {
            dataDeposition.push(dataDocument.data());
        });

        return dataDeposition;
    } catch (error) {
        console.log(error);

        return [];
    }
};

export const getAllActiveDepositions = async (limit) => {
    try {
        const collectionRef = collection(firestore, CollectionName);
        const queryResult = query(collectionRef, where("active", "==", true));
        const querySnapshot = await getDocs(queryResult);

        const dataDepositions = [];

        querySnapshot.forEach((dataQuery) => {
            dataDepositions.push(dataQuery.data());
        });

        if (limit) {
            return dataDepositions.filter((data, index) => {
                if (index + 1 <= limit) {
                    return data;
                }

                return null;
            });
        }

        return dataDepositions;
    } catch (error) {
        console.log(error);

        return [];
    }
};
