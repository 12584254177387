import styled from "styled-components";

export const Container = styled.div`
    .colornav {
        padding-left: 2%;
        padding-right: 2%;
        background-color: black;
        opacity: 0.8;
        margin-top: 1%;
    }

    .divlogo {
        background-color: ${(props) => props.theme.colors.primary};
        margin: 0 auto;
        text-align: center;
        padding-top: 1%;
        margin-bottom: 5%;
    }
`;
