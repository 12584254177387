/* eslint-disable import/prefer-default-export */

import styled from "styled-components";

const Container = styled.div`
    width: 80%;
    margin: 0 auto;
    text-align: justify;

    h5 {
        font-family: ${(props) => props.theme.fonts.primary};

        strong {
            font-family: ${(props) => props.theme.fonts.primary};
        }
    }
`;

export { Container };
