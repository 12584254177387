import styled from "styled-components";

export const Container = styled.div`
    .colorFooter {
        background-color: ${(props) => props.theme.colors.primary};
    }

    .footer {
        margin: 0 auto;
        padding-bottom: 0.1%;
        text-align: center;
    }

    .spacerowFooter {
        width: 80%;
        margin: 0 auto;

        text-align: center;
    }

    .logoFooter {
        margin-top: 8%;
    }
    .descriptionFooter {
        text-align: justify;
        color: white;
    }

    .links {
        padding-top: 8%;
        color: white;
    }

    .linksnav {
        color: white;
        text-left: left;
    }

    .linksnav:hover {
        color: black;
        text-left: left;
    }

    .descriptionFooterContato {
        color: white;
        margin-top: 5%;
    }

    .linksContato {
        padding-top: 8%;
        color: white;
        margin-bottom: 8%;
    }

    .icon {
        width: 35px;
        height: 35px;
        background-color: ${(props) => props.theme.colors.primary};
        border-radius: 100px;
        color: white;
        padding-top: 1.5%;
        padding-left: 0.5%;
        display: inline-block;
        margin-right: 3%;
        box-shadow: 0px 0px 5px #ccc;
        margin-top: 2%;

        @media only screen and (max-width: 600px) {
            padding-top: 2.5%;
            paddign-left: 1%;
        }
    }

    .linefooter {
        margin-top: 20px;
        margin-bottom: 10px;
        height: 1px;
        background-color: white;
        color: white;
    }

    .copyright {
        color: white;
    }

    .icons {
        margin-left: 7%;
    }
`;
