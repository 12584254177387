/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */

import { HeaderSimple } from "../../components/Header";

import { Container } from "./styles";

const PrivacyPolicy = () => (
    <Container>
        <HeaderSimple />
        <h5>Política de Privacidade da logicphire.com.br</h5>
        <p>Este Aplicativo recolhe alguns Dados Pessoais dos Usuários.</p>
        <p>
            Este documento contém uma seção dedicada a Usuários brasileiros e
            seus direitos de privacidade.
        </p>
        <p>
            Este documento pode ser impresso para fins de consulta, através do
            comando imprimir nas configurações de seu navegador.
        </p>
        <h5>Proprietário e Controlador de Dados</h5>
        <p>Logicphire, Brasil/CE</p>
        <p>
            <strong>E-mail de contato do Proprietário: </strong>

            <a
                target="_blank"
                rel="noreferrer"
                href="mailto:logicphire@hotmail.com"
            >
                logicphire@logicphire.com.br
            </a>
        </p>
        <h5>Tipos de Dados coletados</h5>
        <p>
            Entre os tipos de Dados Pessoais que este Site coleta, por si mesmo
            ou através de terceiros, existem: nome; e-mail; número de telefone;
            Rastreador.
        </p>
        <p>
            Detalhes completos sobre cada tipo de Dados Pessoais coletados são
            fornecidos nas seções dedicadas desta política de privacidade ou por
            textos explicativos específicos exibidos antes da coleta de Dados.
            Os Dados Pessoais poderão ser fornecidos livremente pelo Usuário,
            ou, no caso dos Dados de Utilização, coletados automaticamente ao se
            utilizar este Site.
        </p>
        <p>
            A menos que especificado diferentemente todos os Dados solicitados
            por este Site são obrigatórios e a falta de fornecimento destes
            Dados poderá impossibilitar este Site de fornecer os seus Serviços.
            Nos casos em que este Site afirmar especificamente que alguns Dados
            não forem obrigatórios, os Usuários ficam livres para deixarem de
            comunicar estes Dados sem nenhuma consequência para a
            disponibilidade ou o funcionamento do Serviço. Os Usuários que
            tiverem dúvidas a respeito de quais Dados Pessoais são obrigatórios
            estão convidados a entrar em contato com o Proprietário.
        </p>
        <p>
            Quaisquer usos de cookies – ou de outras ferramentas de rastreamento
            – por este Site ou pelos proprietários de serviços terceiros
            utilizados por este Site serão para a finalidade de fornecer os
            Serviços solicitados pelo Usuário, além das demais finalidades
            descritas no presente documento e na Política de Cookies, se estiver
            disponível.
        </p>
        <p>
            Os Usuários ficam responsáveis por quaisquer Dados Pessoais de
            terceiros que forem obtidos, publicados ou compartilhados através
            deste Serviço (este Site) e confirmam que possuem a autorização dos
            terceiros para fornecerem os Dados para o Proprietário.
        </p>
        <h5>
            <strong>Modo e local de processamento dos Dados</strong>
        </h5>
        <h6>Método de processamento</h6>
        <p>
            O Proprietário tomará as medidas de segurança adequadas para impedir
            o acesso não autorizado, divulgação, alteração ou destruição não
            autorizada dos Dados. O processamento dos Dados é realizado
            utilizando computadores e /ou ferramentas de TI habilitadas,
            seguindo procedimentos organizacionais e meios estritamente
            relacionados com os fins indicados. Além do Proprietário, em alguns
            casos, os Dados podem ser acessados por certos tipos de pessoas
            encarregadas, envolvidas com a operação deste Serviço (este Site)
            (administração, vendas, marketing, administração legal do sistema)
            ou pessoas externas (como fornecedores terceirizados de serviços
            técnicos, carteiros, provedores de hospedagem, empresas de TI,
            agências de comunicação) nomeadas, quando necessário, como
            Processadores de Dados por parte do Proprietário. A lista atualizada
            destas partes pode ser solicitada ao Proprietário a qualquer
            momento.
        </p>
        <h6>
            <strong>Base jurídica para o processamento</strong>
        </h6>
        <p>
            O Proprietário poderá processar os Dados Pessoais relacionados ao
            Usuário se uma das hipóteses a seguir se aplicar:
        </p>
        <ul>
            <li>
                os Usuários tenham dado a sua anuência para uma ou mais
                finalidades específicas; Observação: De acordo com algumas
                legislações o Proprietário poderá ter a permissão para processar
                os Dados Pessoais ATÉ QUE O Usuário faça objeção a isto
                (“opt-out”), sem ter que se basear em anuência ou em quaisquer
                outras bases jurídicas a seguir. Isto contudo não se aplica
                sempre que o processamento de Dados Pessoais estiver sujeito à
                legislação europeia de proteção de dados;
            </li>
            <li>
                o fornecimento dos Dados for necessário para o cumprimento de um
                contrato com o Usuário e/ou quaisquer obrigações pré-contratuais
                do mesmo;
            </li>
            <li>
                o processamento for necessário para o cumprimento de uma
                obrigação jurídica à qual o Proprietário estiver sujeito;
            </li>
            <li>
                o processamento estiver relacionado a uma tarefa que for
                executada no interesse público ou no exercício de uma
                autorização oficial na qual o Proprietário estiver investido;
            </li>
            <li>
                o processamento for necessário para a finalidade de interesses
                legítimos perseguidos pelo Proprietário ou por um terceiro;
            </li>
        </ul>
        Em qualquer caso, o Proprietário colaborará de bom grado para esclarecer
        qual a base jurídica que se aplica ao processamento, e em especial se o
        fornecimento de Dados for um requisito obrigatório por força de lei ou
        contratual, ou uma exigência necessária para celebrar um contrato.
        <h6>
            <strong>Lugar</strong>
        </h6>
        <p>
            Os dados são processados nas sedes de operação dos Proprietários, e
            em quaisquer outros lugares onde as partes envolvidas com o
            processamento estiverem localizadas. Dependendo da localização do
            Usuário as transferências de dados poderão envolver a transferência
            dos Dados do Usuário para outro país que não seja o seu. Para
            descobrirem mais sobre o local de processamento de tais Dados
            transferidos os Usuários poderão verificar a seção contendo os
            detalhes sobre o processamento de Dados Pessoais.
        </p>
        <p>
            Os Usuários também possuem o direito de serem informados sobre a
            base jurídica das transferências de Dados para países de fora da
            União Europeia ou para quaisquer organizações internacionais regidas
            pelo direito internacional público ou formadas por dois ou mais
            países, tal como a ONU, e sobre as medidas de segurança tomadas pelo
            Proprietário para proteger os seus Dados.
        </p>
        <p>
            Se ocorrerem quaisquer tais transferências os Usuários poderão
            descobrir mais a respeito verificando as seções pertinentes deste
            documento ou perguntando ao Proprietário utilizando as informações
            fornecidas na seção de contatos.
        </p>
        <h6>
            <strong>Período de conservação</strong>
        </h6>
        <p>
            Os Dados Pessoais serão processados e armazenados pelo tempo que for
            necessário para as finalidades para as quais forem coletados. &nbsp;
            Portanto:
        </p>
        <ul>
            <li>
                Os Dados Pessoais coletados para as finalidades relacionadas com
                a execução de um contrato entre o Proprietário e o Usuário serão
                conservados até que tal contrato tenha sido completamente
                cumprido.
            </li>
            <li>
                Os Dados Pessoais coletados para as finalidades relacionadas com
                os legítimos interesses do Proprietário serão conservados pelo
                tempo que for necessário para cumprir tais finalidades. Os
                Usuários poderão obter informações específicas sobre os
                interesses legítimos perseguidos pelo Proprietário dentro das
                seções pertinentes deste documento ou entrando em contato com o
                Proprietário.
            </li>
        </ul>
        <p>
            O Proprietário poderá ter a permissão de conservar os Dados Pessoais
            por um prazo maior sempre que o Usuário tiver dado a sua autorização
            para tal processamento, enquanto tal autorização não tiver sido
            retirada. Além disso, o Proprietário poderá ficar obrigado a
            conservar os Dados Pessoais por um prazo maior em todas as ocasiões
            em que estiver obrigado a fazê-lo para o cumprimento de uma
            obrigação jurídica ou em cumprimento de um mandado de uma
            autoridade.
        </p>
        <p>
            Assim que o prazo de conservação vencer os Dados Pessoais serão
            apagados. Desta forma o direito de acessar, o direito de apagar, o
            direito de corrigir e o direito à portabilidade dos dados não
            poderão ter o seu cumprimento exigido após o vencimento do prazo de
            conservação.
        </p>
        <h6>
            <strong>As finalidades do processamento</strong>
        </h6>
        <p>
            Os Dados relativos ao Usuário são coletados para permitir que o
            Proprietário preste seu Serviço, cumpra suas obrigações legais,
            responda a solicitações de execução, proteja seus direitos e
            interesses (ou aqueles de seus Usuários ou terceiros), detecte
            qualquer atividade maliciosa ou fraudulenta, assim como: Comentário
            de conteúdo, Contatar o Usuário e Hospedagem e infraestrutura de
            backend.
        </p>
        <p>
            Para obter informações específicas sobre os Dados Pessoais
            utilizados para cada finalidade, o Usuário poderá consultar a seção
            "Informações detalhadas sobre o processamento de Dados Pessoais".
        </p>
        <h6>
            <strong>
                Informações detalhadas sobre o processamento de Dados Pessoais
            </strong>
        </h6>
        <p>
            Os Dados Pessoais são recolhidos para os seguintes fins e utilizando
            os seguintes serviços:
        </p>
        <h6>
            <strong>Comentário de conteúdo</strong>
        </h6>
        <p>
            Os serviços de comentário de conteúdo permitem Usuários a criar e
            publicar comentários sobre o conteúdo deste serviço (este Site).
            Dependendo das configurações escolhidas pelo Proprietário, os
            Usuários podem também deixar comentários anônimos. Se houver um
            endereço de e-mail entre os Dados Pessoais fornecidos pelo Usuário,
            poderá ser usado para enviar notificações de comentários sobre o
            mesmo conteúdo. Os Usuários são responsáveis pelo conteúdo de seus
            comentários. Se um serviço de comentário de conteúdo prestado por
            terceiros esteja instalado, este ainda poderá coletar dados de
            tráfego da web para as páginas onde o serviço de comentário esteja
            instalado, mesmo quando os usuários não usam o serviço de conteúdo
            de comentário. Sistema de Comentário gerido diretamente (este Site)
            Este Site tem seu próprio sistema de comentários de conteúdo
            interno. Dados Pessoais processados: e-mail; nome.
        </p>
        <h6>
            <strong>Contatar o Usuário</strong>
        </h6>
        <p>
            Formulário de contato (este Site) Ao preencher o formulário de
            contato com os seus Dados, os usuários autorizam este Site a usar
            esses detalhes para responder aos pedidos de informações, cotação ou
            qualquer outro tipo de pedido como indicado pelo título do
            formulário. Dados Pessoais processados: e-mail; nome; número de
            telefone.
        </p>
        <h6>
            <strong>Hospedagem e infraestrutura de backend</strong>
        </h6>
        <p>
            Estes tipos de serviços têm a finalidade de hospedagem de Dados e
            arquivos que permitem que este Site seja executado e distribuído,
            bem com para fornecer infraestrutura para executar recursos ou
            partes deste serviço (este Site). Alguns serviços entre os listados
            abaixo, se houver, podem funcionar através de servidores
            distribuídos geograficamente, tornando difícil determinar a
            localização real onde os Dados Pessoais são armazenados.
        </p>
        <h6>
            <strong>
                *<u>DigitalOcean (DigitalOcean, Inc.)</u>
            </strong>
        </h6>
        <p>
            DigitalOcean é um provedor americano de infraestrutura em nuvem com
            sede na cidade de Nova York e centros de dados em todo o mundo. A
            DigitalOcean fornece aos desenvolvedores serviços em nuvem que
            ajudam a implantar e dimensionar Sites que são executados
            simultaneamente em vários computadores. Dados Pessoais processados:
            vários tipos de Dados como especificados na política de privacidade
            do serviço. Lugar de processamento: EUA –{" "}
            <a
                target="_blank"
                rel="noreferrer"
                href="https://www.digitalocean.com/legal/privacy-policy/"
            >
                <strong>Política de Privacidade</strong>
            </a>
            .
        </p>
        <h6>
            <strong>Monitoramento de infraestrutura</strong>
        </h6>
        <p>
            Esses tipos de serviços permitem que este Site monitore o uso e o
            comportamento de seus componentes para que o seu desempenho,
            operação, manutenção e solução de problemas possam ser melhorados.
            Quais os dados pessoais são tratados depende das características e o
            modo de implementação destes serviços, cuja função é a de filtrar as
            atividades deste serviço (este Site). Sentry (Functional Software,
            Inc. ) Sentry é um serviço de monitoramento fornecido pelo
            Functional Software, Inc. . Dados Pessoais processados: vários tipos
            de Dados como especificados na política de privacidade do serviço.
            Lugar de processamento: EUA –
            <a
                target="_blank"
                rel="noreferrer"
                href="https://sentry.io/privacy/"
            >
                <strong>Política de Privacidade</strong>
            </a>
            .
        </p>
        <h6>
            <strong>Registro e autenticação</strong>
        </h6>
        <p>
            Ao se registrar ou autenticar, os Usuários permitem a este serviço
            (este Site) identificá-los e dar-lhes o acesso a serviços dedicados.
            Dependendo do que estiver descrito abaixo, os serviços de registro e
            autenticação podem ser fornecidos por terceiros. Neste caso, este
            Site poderá acessar alguns Dados armazenados por estes serviços de
            terceiros para fins de registro ou identificação. Alguns dos
            serviços listados abaixo também podem coletar Dados Pessoais para
            fins de direcionamento e perfil; para saber mais, consulte a
            descrição de cada serviço.
        </p>
        <h6>
            <strong>
                *<u>Google OAuth</u>
            </strong>
        </h6>
        <p>
            O Google OAuth é um serviço de registro e autenticação fornecido
            pelo Google LLC ou pela Google Ireland Limited, dependendo do local
            a partir do qual este Site for acessado, e está conectado à rede
            Google. Dados Pessoais processados: vários tipos de Dados como
            especificados na política de privacidade do serviço. Lugar de
            processamento: EUA –{" "}
            <a
                target="_blank"
                rel="noreferrer"
                href="https://policies.google.com/privacy"
            >
                <strong>Política de Privacidade</strong>
            </a>
            ; Irlanda –{" "}
            <a
                target="_blank"
                rel="noreferrer"
                href="https://policies.google.com/privacy"
            >
                <strong>Política de Privacidade</strong>
            </a>
            .
        </p>
        <h6>
            <strong>
                Registro e autenticação fornecidos diretamente por este Site
            </strong>
        </h6>
        <p>
            Ao se registrar ou autenticar, os Usuários permitem a este serviço
            (este Site) identificá-los e dar-lhes o acesso a serviços dedicados.
            Os Dados Pessoais são coletados e armazenados somente para fins de
            registro ou identificação. Os Dados coletados são somente aqueles
            necessários para a prestação do serviço solicitado pelos Usuários. *
        </p>
        <h6>
            <strong>
                <u>Registro direto (este Site)</u>
            </strong>
        </h6>
        <p>
            O Usuário se registra ao preencher o formulário de inscrição e
            fornecer os Dados Pessoais diretamente para este Site. Dados
            Pessoais processados: e-mail; nome; nome de Usuário; senha;
            sobrenome.
        </p>
        <h6>
            <strong>Serviços de plataforma e hospedagem</strong>
        </h6>
        <p>
            Estes serviços têm como objetivo hospedar e rodar componentes-chave
            para este Site, permitindo, assim, a oferta deste Site dentro de uma
            plataforma unificada. Tais plataformas oferecem uma vasta gama de
            ferramentas ao Proprietário – como análises, registro de usuários,
            comentários, gerenciamento de banco de dados, e-commerce,
            processamento de pagamentos – o que resulta na coleta e manuseio de
            Dados Pessoais. Alguns destes serviços funcionam através de
            servidores distribuídos geograficamente, o que torna difícil saber
            onde exatamente os Dados Pessoais estão armazenados. *
        </p>
        <h6>
            <strong>
                <u>Bravulink.com.br (Bravulink Inc.)</u>
            </strong>
        </h6>
        <p>
            Bravulink.com.br é uma plataforma de hospedagem que permite que o
            Proprietário construa, execute e hospede este Site. Dados Pessoais
            processados: Todos Dados que partilha durante o uso do serviço;
            vários tipos de Dados como especificados na política de privacidade
            do serviço. Lugar de processamento: EUA –{" "}
            <a
                target="_blank"
                rel="noreferrer"
                href="https://bravulink.com.br/politica-de-privacidade/"
            >
                <strong>Política de Privacidade</strong>
            </a>
            .
        </p>
        <h6>
            <strong>Visualizar conteúdo de plataformas externas</strong>
        </h6>
        <p>
            Estes tipos de serviços permitem que você visualize e interaja com o
            conteúdo hospedado em plataformas externas diretamente a partir das
            páginas deste serviço (este Site) e interaja com estes. Este tipo de
            serviço poderá ainda coletar dados de tráfego da web para as páginas
            onde o serviço estiver instalado, mesmo quando os usuários não os
            estiverem utilizando. *
        </p>
        <h6>
            <strong>
                <u>Font Awesome (Fonticons, Inc. )</u>
            </strong>
        </h6>
        <p>
            Font Awesome é um serviço de visualização de face de tipo fornecido
            pelo Fonticons, Inc. que permite a este Site a incorporar conteúdo
            desse tipo em suas páginas. Dados Pessoais processados: Dados de
            uso; Rastreador. Lugar de processamento: EUA –{" "}
            <a
                target="_blank"
                rel="noreferrer"
                href="https://fontawesome.com/privacy"
            >
                <strong>Política de Privacidade</strong>
            </a>
            . *
        </p>
        <h6>
            <strong>
                <u>Google Fonts</u>
            </strong>
        </h6>
        <p>
            Google Fonts é um serviço de visualização de face de tipo fornecido
            pelo Google LLC ou pela Google Ireland Limited, dependendo do local
            a partir do qual este Site for acessado, que permite este Site a
            incorporar conteúdo desse tipo em suas páginas. Dados Pessoais
            processados: Dados de uso; Rastreador. Lugar de processamento: EUA –{" "}
            <a
                target="_blank"
                rel="noreferrer"
                href="https://policies.google.com/privacy"
            >
                <strong>Política de Privacidade</strong>
            </a>
            ; Irlanda –{" "}
            <a
                target="_blank"
                rel="noreferrer"
                href="https://policies.google.com/privacy"
            >
                <strong>Política de Privacidade</strong>
            </a>
            .
        </p>
        <h5>
            <strong>
                Informações adicionais sobre o processamento de Dados Pessoais
            </strong>
        </h5>
        <h6>
            <strong>LocalStorage</strong>
        </h6>
        <p>
            localStorage permite que este Site armazene e acesse dados
            diretamente no navegador do Usuário, sem data de expiração. Os Dados
            que se encontram em localStorage podem ser excluídos, limpando-se o
            histórico do navegador.
        </p>
        <h5>
            <strong>Os direitos dos Usuários</strong>
        </h5>
        <p>
            Os Usuários poderão exercer determinados direitos a respeito dos
            seus Dados processados pelo Proprietário. Em especial, os Usuários
            possuem os direitos a fazer o seguinte:
        </p>
        <ul>
            <li>
                Retirar a sua anuência a qualquer momento. Os Usuários possuem o
                direito de retirar a sua anuência nos casos em que tenham dado a
                sua anuência anteriormente para o processamento dos seus Dados
                Pessoais.
            </li>
            <li>
                Objetar o processamento dos seus Dados. Os Usuários possuem o
                direito de objetar o processamento dos seus Dados se o
                processamento for executado sobre outra base jurídica que não a
                anuência. São fornecidos detalhes adicionais na seção específica
                abaixo.
            </li>
            <li>
                Acessar os seus Dados. Os Usuários possuem o direito de saber se
                os seus Dados estão sendo tratados pelo Proprietário, obter
                revelações sobre determinados aspectos do tratamento e conseguir
                uma cópia dos Dados que estiverem sendo tratados.
            </li>
            <li>
                Verificar e pedir retificação. Os Usuários possuem o direito de
                verificar a exatidão dos seus Dados e de pedir que os mesmos
                sejam atualizados ou corrigidos.
            </li>
            <li>
                Restringir o processamento dos seus Dados. Os Usuários possuem o
                direito de, sob determinadas circunstâncias, restringir o
                processamento dos seus Dados para qualquer outra finalidade que
                não seja o armazenamento dos mesmos.
            </li>
            <li>
                Ter os seus Dados Pessoais apagados ou retirados de outra
                maneira. Os Usuários possuem o direito de, sob determinadas
                circunstâncias, obter a eliminação dos seus Dados do
                Proprietário.
            </li>
            <li>
                Receber os seus Dados e ter os mesmos transferidos para outro
                controlador. Os Usuários possuem o direito de receber os seus
                Dados em um formato estruturado, utilizado comumente e apto a
                ser lido por máquinas e, se for viável tecnicamente, fazer com
                que os mesmos sejam transmitidos para outro controlador sem
                nenhum empecilho. Esta determinação se aplica condicionada a que
                os Dados sejam processados por meios automatizados e que o
                processamento esteja baseado na anuência do Usuário, em um
                contrato do qual o Usuário seja uma das partes ou por obrigações
                pré-contratuais do mesmo.
            </li>
            <li>
                Registrar uma reclamação. Os Usuários possuem o direito de
                apresentar reclamação perante a sua autoridade de proteção de
                dados competente.
            </li>
        </ul>
        <h6>
            <strong>
                Detalhes sobre o direito de objetar ao processamento
            </strong>
        </h6>
        <p>
            Nos casos em que os Dados Pessoais forem processados por um
            interesse público, no exercício de uma autorização oficial na qual o
            Proprietário estiver investido ou para finalidades dos interesses
            legítimos perseguidos pelo Proprietário, os Usuários poderão objetar
            tal processamento através do fornecimento de um motivo relacionado
            com a sua situação em especial para justificar a objeção. Os
            Usuários devem saber, entretanto, que caso os seus Dados Pessoais
            sejam processados para finalidades de marketing direto eles podem
            objetar tal processamento a qualquer momento sem fornecer nenhuma
            justificativa. Os Usuários podem consultar as seções respectivas
            deste documento.
        </p>
        <h6>
            <strong>Como exercer estes direitos</strong>
        </h6>
        <p>
            Quaisquer pedidos para exercer os direitos dos Usuários podem ser
            direcionados ao Proprietário através dos dados para contato
            fornecidos neste documento. Estes pedidos podem ser exercidos sem
            nenhum custo e serão atendidos pelo Proprietário com a maior
            brevidade possível e em todos os casos em prazo inferior a um mês.
        </p>
        <h5>
            <strong>
                Informações adicionais sobre a coleta e processamento de Dados
            </strong>
        </h5>
        <h6>
            <strong>Ação jurídica</strong>
        </h6>
        <p>
            Os Dados Pessoais dos Usuários podem ser utilizados para fins
            jurídicos pelo Proprietário em juízo ou nas etapas conducentes à
            possível ação jurídica decorrente de uso indevido deste Serviço
            (este Site) ou dos Serviços relacionados. O Usuário declara estar
            ciente de que o Proprietário poderá ser obrigado a revelar os Dados
            Pessoais mediante solicitação das autoridades governamentais.
        </p>
        <h6>
            <strong>
                Informações adicionais sobre os Dados Pessoais do Usuário
            </strong>
        </h6>
        <p>
            Além das informações contidas nesta política de privacidade, este
            Site poderá fornecer ao Usuário informações adicionais e contextuais
            sobre os serviços específicos ou a coleta e processamento de Dados
            Pessoais mediante solicitação.
        </p>
        <h6>
            <strong>Logs do sistema e manutenção</strong>
        </h6>
        <p>
            Para fins de operação e manutenção, este Site e quaisquer serviços
            de terceiros poderão coletar arquivos que gravam a interação com
            este Site (logs do sistema) ou usar outros Dados Pessoais (tais como
            endereço IP) para esta finalidade.
        </p>
        <h6>
            <strong>As informações não contidas nesta política</strong>
        </h6>
        <p>
            Mais detalhes sobre a coleta ou processamento de Dados Pessoais
            podem ser solicitados ao Proprietário, a qualquer momento. Favor ver
            as informações de contato no início deste documento.
        </p>
        <h6>
            <strong>Como são tratados os pedidos de “Não me Rastreie”</strong>
        </h6>
        <p>
            Este Site não suporta pedidos de “Não Me Rastreie”. Para determinar
            se qualquer um dos serviços de terceiros que utiliza honram
            solicitações de “Não Me Rastreie”, por favor leia as políticas de
            privacidade.
        </p>
        <h6>
            <strong>Mudanças nesta política de privacidade</strong>
        </h6>
        <p>
            O Proprietário se reserva o direito de fazer alterações nesta
            política de privacidade a qualquer momento, através de notificação a
            seus Usuários nesta página e possivelmente dentro deste Serviço
            (este Site) e/ou – na medida em que for técnica e juridicamente
            viável – enviando um aviso para os Usuários através de quaisquer
            informações de contato disponíveis para o Proprietário. É altamente
            recomendável checar esta página regularmente, consultando a data da
            última modificação informada na parte inferior. Caso as mudanças
            afetem as atividades de processamento realizadas com base na
            anuência do Usuário, o Proprietário coletará nova anuência do
            Usuário, onde for exigida.
        </p>
        <h5>
            <strong>Informações para Usuários que residem no Brasil</strong>
        </h5>
        <p>
            Esta parte do documento se integra e complementa as informações
            contidas no resto desta política de privacidade e é fornecida pela
            entidade que opera este Site e, conforme for o caso, suas
            controladoras, subsidiárias e afiliadas (para fins desta seção,
            doravante denominadas em conjunto através dos pronomes "nós", "nos",
            "nosso" e "conosco"). As disposições contidas nesta seção
            aplicar-se-ão a todos os Usuários que sejam residentes no Brasil, de
            acordo com a "Lei geral de Proteção de Dados" (os Usuários serão
            doravante denominados através dos pronomes "você" e "seu"). Para
            tais Usuários, estas disposições substituem todas as outras
            disposições possivelmente divergentes ou conflitantes contidas nesta
            política de privacidade. Esta parte do documento usa o termo
            "informações pessoais", conforme definido na Lei Geral de Proteção
            de Dados (LGPD).
        </p>
        <h6>
            <strong>
                Em que nos embasamos para processar suas informações pessoais
            </strong>
        </h6>
        <p>
            Podemos processar suas informações pessoais somente se tivermos uma
            base legal para tal processamento. As bases legais são as seguintes:
        </p>
        <ul>
            <li>
                sua anuência com as respectivas atividades de processamento;
            </li>
            <li>
                conformidade com uma obrigação legal ou regulamentar imposta a
                nós;
            </li>
            <li>
                o cumprimento de políticas públicas dispostas em leis ou
                regulamentações ou com base em contratos, acordos e instrumentos
                legais semelhantes;
            </li>
            <li>
                estudos conduzidos por entidades de pesquisa, preferivelmente
                realizados sobre informações pessoais anônimas;
            </li>
            <li>
                a execução de um contrato e seus procedimentos preliminares, nos
                casos em que você for parte do contrato;
            </li>
            <li>
                o exercício de nossos direitos em processos judiciais,
                administrativos ou de arbitragem;
            </li>
            <li>proteção ou segurança física de você ou de um terceiro;</li>
            <li>
                a proteção da saúde – em procedimentos realizados por entidades
                ou profissionais da saúde;
            </li>
            <li>
                nossos interesses legítimos, desde que seus direitos e
                liberdades fundamentais não prevaleçam sobre tais interesses; e
            </li>
            <li>proteção ao crédito.</li>
        </ul>
        <p>
            Para saber mais sobre as bases legais, você pode entrar em contato
            conosco a qualquer momento através dos dados de contato fornecidos
            neste documento.
        </p>
        <h6>
            <strong>Categorias de informações pessoais processadas</strong>
        </h6>
        <p>
            Para saber que categorias de suas informações pessoais são
            processadas, você poderá ler a seção intitulada "Informações
            detalhadas sobre o processamento de Dados Pessoais" neste documento.
        </p>
        <h6>
            <strong>Por que processamos suas informações pessoais</strong>
        </h6>
        <p>
            Para saber por que processamos suas informações pessoais, você
            poderá ler sobre as seções intituladas "Informações detalhadas sobre
            o processamento de Dados Pessoais" e "As finalidades do
            processamento" neste documento.
        </p>
        <h5>
            <strong>
                Seus direitos de privacidade como brasileiro, como enviar uma
                solicitação e nossas respostas a suas solicitações
            </strong>
        </h5>
        <h6>
            <strong>Seus direitos de privacidade como brasileiro</strong>
        </h6>
        <p>Você tem o direito de:</p>
        <ul>
            <li>
                obter confirmação sobre a existência de atividades de tratamento
                de suas informações pessoais;
            </li>
            <li>acesso a suas informações pessoais;</li>
            <li>
                fazer com que suas informações pessoais incompletas, inexatas ou
                desatualizadas sejam corrigidas;
            </li>
            <li>
                obter o anonimato, bloqueio ou eliminação de suas informações
                pessoais desnecessárias ou em excesso, ou de informações que não
                estejam sendo processadas de acordo com a LGPD;
            </li>
            <li>
                obter informações sobre a possibilidade de fornecer ou recusar
                sua anuência e as respectivas consequências;
            </li>
            <li>
                obter informações sobre os terceiros com quem compartilhamos
                suas informações pessoais;
            </li>
            <li>
                obter, mediante sua solicitação expressa, a portabilidade de
                suas informações pessoais (exceto informações anônimas) para
                outro fornecedor de produtos ou serviços, desde que nossos
                segredos comerciais e industriais continuem protegidos;
            </li>
            <li>
                obter a exclusão de suas informações pessoais tratadas, se o
                tratamento teve base em sua anuência, a menos que se apliquem
                uma ou mais exceções daquelas dispostas no art. 16 da LGPD;
            </li>
            <li>retirar sua anuência a qualquer momento;</li>
            <li>
                registrar uma reclamação com relação a suas informações pessoais
                à ANPD (Autoridade Nacional de Proteção de Dados) ou aos órgãos
                de proteção ao consumidor;
            </li>
            <li>
                opor-se a uma atividade de tratamento nos casos em que o
                tratamento não for realizado em conformidade com as disposições
                da lei;
            </li>
            <li>
                solicitar informações claras e adequadas a respeito dos
                critérios e procedimentos usados para uma decisão automatizada;
                e
            </li>
            <li>
                solicitar a revisão de decisões tomadas exclusivamente com base
                no tratamento automatizado de suas informações pessoais, que
                afetem seus interesses. Nisto estão incluídas decisões para
                definir seu perfil pessoal, profissional, de consumidor e de
                crédito ou aspectos de sua personalidade.
            </li>
        </ul>
        <p>
            Você nunca será discriminado ou sofrerá qualquer tipo de prejuízo se
            exercer seus direitos.
        </p>
        <h6>
            <strong>Como registrar sua solicitação</strong>
        </h6>
        <p>
            Você poderá registrar sua solicitação expressa de exercer seus
            direitos gratuitamente , em qualquer momento, usando os dados de
            contato fornecidos neste documento ou através de seu representante
            legal.
        </p>
        <h6>
            <strong>Como e quando nós responderemos a sua solicitação</strong>
        </h6>
        <p>
            Faremos o possível para responder imediatamente a suas solicitações.
            Em qualquer caso, caso seja impossível, faremos questão de comunicar
            a você os motivos de fato ou de direito que nos impeçam de atender
            imediatamente, ou de qualquer outra forma, cumprir suas
            solicitações. Nos casos em que não estivermos processando suas
            informações pessoais, indicaremos a pessoa física ou jurídica a quem
            você deve encaminhar suas solicitações, se estivermos na posição de
            fazê-lo. Caso você registre uma solicitação de acesso ou de
            confirmação de processamento de informações pessoais, certifique-se
            de especificar se deseja que suas informações pessoais sejam
            entregues em formato eletrônico ou impresso. Você também precisará
            nos informar se deseja que respondamos sua solicitação
            imediatamente, sendo que neste caso responderemos de maneira
            simplificada ou se você precisa de uma resposta completa. Neste
            último caso, responderemos no prazo de 15 dias a contar do momento
            de sua solicitação, fornecendo todas as informações sobre a origem
            de suas informações pessoais, confirmação da existência ou não de
            registros, quaisquer critérios utilizados para o processamento e as
            finalidades do processamento, mantendo protegidos nossos segredos
            comerciais e industriais. Caso você solicite uma retificação,
            exclusão, anonimato ou bloqueio de informações pessoais,
            comunicaremos imediatamente sua solicitação a outras partes com quem
            compartilhamos suas informações pessoais, a fim de permitir que
            estes terceiros também cumpram sua solicitação – exceto nos casos em
            que tal comunicação se provar impossível ou envolver um esforço
            desproporcional de nosso lado.
        </p>
        <h6>
            <strong>
                Transferência de informações pessoais para fora do Brasil
                permitida por lei
            </strong>
        </h6>
        <p>
            Temos permissão para transferir suas informações pessoais para fora
            do território brasileiro nos seguintes casos:
        </p>
        <ul>
            <li>
                quando a transferência for necessária para fins de cooperação
                jurídica internacional entre órgãos públicos de inteligência,
                investigação e Ministério Público, de acordo com os meios legais
                dispostos no direito internacional;
            </li>
            <li>
                quando a transferência for necessária para proteger sua vida ou
                segurança física ou de terceiros;
            </li>
            <li>quando a transferência for autorizada pela ANPD;</li>
            <li>
                quando a transferência decorrer de um compromisso assumido em um
                acordo de cooperação internacional;
            </li>
            <li>
                quando a transferência for necessária para a execução de uma
                política pública ou atribuição legal de serviço público;
            </li>
            <li>
                quando a transferência for necessária para o cumprimento de uma
                obrigação legal ou regulamentar, a execução de um contrato ou
                procedimentos preliminares relacionados a um contrato ou o
                exercício regular de direitos em processos judiciais,
                administrativos ou de arbitragem.
            </li>
        </ul>
        <h5>
            <strong>Definições e referências jurídicas</strong>
        </h5>
        <h6>
            <strong>Dados Pessoais (ou Dados)</strong>
        </h6>
        <p>
            Quaisquer informações que diretamente, indiretamente ou em relação
            com outras informações – incluindo um número de identificação
            pessoal – permitam a identificação ou identificabilidade de uma
            pessoa física.
        </p>
        <h6>
            <strong>Dados de Uso</strong>
        </h6>
        <p>
            As informações coletadas automaticamente através deste este Site (ou
            serviços de terceiros contratados neste Serviço (este Site)), que
            podem incluir: os endereços IP ou nomes de domínio dos computadores
            utilizados pelos Usuários que utilizam este Site, os endereços URI
            (Identificador Uniforme de Recurso), a data e hora do pedido, o
            método utilizado para submeter o pedido ao servidor, o tamanho do
            arquivo recebido em resposta, o código numérico que indica o status
            do servidor de resposta (resultado positivo, erro , etc.), o país de
            origem, as características do navegador e do sistema operacional
            utilizado pelo Usuário, os vários detalhes de tempo por visita (por
            exemplo, o tempo gasto em cada página dentro do Site) e os detalhes
            sobre o caminho seguido dentro da aplicação, com especial referência
            à sequência de páginas visitadas e outros parâmetros sobre o sistema
            operacional do dispositivo e/ou ambiente de TI do Usuário.
        </p>
        <h6>
            <strong>Usuário</strong>
        </h6>
        <p>
            A pessoa que usa este Site que, a menos que especificado
            diferentemente, coincida com o Titular dos Dados.
        </p>
        <h6>
            <strong>Titular dos Dados</strong>
        </h6>
        <p>A pessoa física a quem os Dados Pessoais se referem.</p>
        <h6>
            <strong>Processador de Dados (ou supervisor de Dados)</strong>
        </h6>
        <p>
            A pessoa física ou jurídica, administração pública, agência ou outro
            órgão que processe os Dados Pessoais em nome do Controlador conforme
            descrito nesta política de privacidade.
        </p>
        <h6>
            <strong>Controlador de Dados (ou Proprietário)</strong>
        </h6>
        <p>
            A pessoa física ou jurídica, administração pública, agência ou outro
            órgão que, isoladamente ou em conjunto com outros determinar as
            finalidades e os meios de processamento dos Dados Pessoais,
            incluindo medidas de segurança relativas ao funcionamento e ao uso
            deste Serviço (este Site). O Controlador de Dados, a menos que seja
            especificado de outra forma, é o Proprietário deste Serviço (este
            Site).
        </p>
        <h6>
            <strong>Este Site</strong>
        </h6>
        <p>
            O meio pelo qual os Dados Pessoais do Usuário são coletados e
            processados.
        </p>
        <h6>
            <strong>Serviço</strong>
        </h6>
        <p>
            O serviço fornecido por este Site conforme descrito nos termos
            relativos (se disponíveis) e neste site/Site.
        </p>
        <h6>
            <strong>União Europeia (ou UE)</strong>
        </h6>
        <p>
            A menos que especificado diferentemente, todas as referências feitas
            neste documento à União Europeia incluem todos os atuais estados
            membros da União Europeia e do Espaço Econômico Europeu.
        </p>
        <h6>
            <strong>Cookie</strong>
        </h6>
        <p>
            Cookies são Rastreadores compostos por pequenos conjuntos de dados
            armazenados no navegador do Usuário.
        </p>
        <h6>
            <strong>Rastreador</strong>
        </h6>
        <p>
            O termo Rastreador indica qualquer tecnologia – como Cookies,
            identificadores únicos, web beacons, scripts embutidos, e-tags e
            fingerprinting – que permita o rastreamento de Usuários, por
            exemplo, acessando ou a armazenando informações no dispositivo do
            Usuário.
        </p>
        <h6>
            <strong>Informação jurídica</strong>
        </h6>
        <p>
            Esta declaração de privacidade foi preparada com base em
            determinações de múltiplas legislações, inclusive os Arts. 13/14 do
            Regulamento (EU) 2016/679 (GDPR - Regulamento Geral de Proteção de
            Dados). Esta política de privacidade se refere somente a este Site,
            se não afirmado diferentemente neste documento.
        </p>
    </Container>
);

export default PrivacyPolicy;
