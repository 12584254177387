import styled from "styled-components";
import Arc from "../../assets/images/teste2.jpg";

export const Container = styled.div`
.startContainer {     
    background-repeat: no-repeat;
    background-image: url(${Arc});
    background-position: center;
    padding-top: 2%;
    max-width: 100%;
    height: auto;
    height: 750px;

    
    @media only screen and (max-width: 600px) {
        height: 500px;
    }
}

    @media (min-width: 576px) {
        max-width: 540px;
        margin: 0 auto;
       
    }

    @media (min-width: 768px) {
        max-width: 720px;
        margin: 0 auto;
       
    }

    @media (min-width: 992px) {
        max-width: 960px;
        margin: 0 auto;
       
    }

    @media (min-width: 1200px) {
        max-width: 1600px;
        margin: 0 auto;
      
      
        
    }


    .sizecard{
        height: 275px;
    }

.divContainerStart {
    text-align: center;
    color: white;
    font-family: ${(props) => props.theme.fonts.primary};
    margin-top: 9%;
}

.buttomStart {
    background-color: ${(props) => props.theme.colors.secondary};
    border-color: ${(props) => props.theme.colors.secondary};
    color: white;
    width: 250px;
    height: 50px;
    margin-top: 1%;
    margin-bottom: 9%;
    border-radius: 12px;
    padding-top: 0.5%;
    box-shadow: 1px 2.5px 1px 1.5px rgba(0, 0, 0, 0.2);
    font-size: 1.2em;
    font-family: ${(props) => props.theme.fonts.primary};

    &:hover {
        background-color: ${(props) => props.theme.colors.hoverSecondary};
        border-color: ${(props) => props.theme.colors.hoverSecondary};
`;

export const ContentAboutUs = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;
    padding: 0 20px;
    margin-top: 3%;

    .contentImage {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        img {
            width: 100%;
        }
    }

    .contentInformation {
        p {
            margin: 0;
            margin-bottom: 1%;
            font-size: 1rem;
        }

        h4 {
            color: ${({ theme }) => theme.colors.primary};
        }

        .aboutUsItem {
            color: ${({ theme }) => theme.colors.text};
            font-size: 0.87rem;
            path {
                color: ${({ theme }) => theme.colors.primary};
            }
            margin-top: 2%;
            margin-bottom: 2%;
        }

        .aboutUs {
            color: black;
        }

        .textAboutUs {
            margin-top: 2%;
        }
    }
`;

export const ContentServices = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 50px 0;

    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};

    margin-top: 3%;

    img {
        width: 70px;
        height: 70px;
    }

    .contentCard {
        width: 80%;
    }

    .contentInformation {
        p {
            margin: 0;
            color: ${({ theme }) => theme.colors.subtext};
        }

        .description {
            margin-bottom: 5%;
        }

        .divider {
            width: 90px;
            height: 3px;

            background-color: ${({ theme }) => theme.colors.white};

            margin-top: 3%;
            margin-bottom: 5%;
        }
        .yourservices {
            margin-bottom: 2%;
        }
    }

    .contentServices {
        h5 {
            margin-top: 6%;
        }

        p {
            margin: 0;
            color: ${({ theme }) => theme.colors.subtext};
            margin-bottom: 5%;
        }
    }
`;

export const ContentBudget = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 3%;

    color: ${({ theme }) => theme.colors.subtext};

    img {
        width: 100%;
    }

    h4 {
        margin-bottom: 2%;
        color: ${({ theme }) => theme.colors.primary};
    }

    .cardBudget {
        width: 80%;
    }

    .description {
        text-align: justify;
        color: ${({ theme }) => theme.colors.text};
    }

    .budget {
        color: #333;
    }

    .contentButtonsBugdet {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;

        margin-top: 5%;

        button.budgetButton {
            width: 50%;
            height: 40px;
            margin-right: 10%;

            border: none;

            background-color: ${({ theme }) => theme.colors.secondary};
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19);

            &:hover {
                background-color: ${({ theme }) => theme.colors.hoverSecondary};
            }
        }

        button.whatsapp {
            width: 45px;
            height: 45px;

            border-radius: 25px;
            padding-bottom: 8px;

            border: none;
            background-color: #25d366;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19);

            &:hover {
                background-color: #23b157;
            }

         
        }
    }
`;
export const ContentTeam = styled.div`
    background-color: ${(props) => props.theme.colors.primary};

    .team {
        width: 80%;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 3%;

        .titleteam {
            padding-top: 2%;

            color: white;
        }

        .paragraphyteam {
            color: white;
            margin-bottom: 5%;
        }

        .textcard {
            text-align: justify;
        }
        

        .imgcardteam {
            width: 80%;
            padding-top: 8%;
        }

        .icon {
            width: 35px;
            height: 35px;
            text-align: center;
            background-color: ${(props) => props.theme.colors.primary};
            border-radius: 100px;
            color: white;
            padding-top: 1%;
            display: inline-block;
            margin-right: 3%;
            box-shadow: 2px 2px 5px #ccc;

            @media only screen and (max-width: 600px) {
                padding-top: 2.5%;
                paddign-left: 1%;
            }
        }
    }
`;
