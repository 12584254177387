/* eslint-disable import/no-mutable-exports */

import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeFirestore } from "firebase/firestore";

import { firebaseConfig } from "./config";

let analytics = null;
let firestore = null;

if (!getApps.length) {
    const firebaseApp = initializeApp(firebaseConfig);

    firestore = initializeFirestore(firebaseApp, {
        experimentalForceLongPolling: true,
    });

    analytics = getAnalytics(firebaseApp);
}

export { analytics, firestore };
