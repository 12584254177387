/* eslint-disable react/jsx-one-expression-per-line */

import { Container, Terms } from "./styles";

import { HeaderSimple } from "../../components/Header";

const UseTerms = () => (
    <Container>
        <HeaderSimple />
        <Terms>
            <h5>Termos e Condições de Uso</h5>
            Seja bem-vindo ao Logicphire.com.br! Estes termos e condições
            descrevem as regras de uso do site da empresa Logicphire, localizada
            no endereço https://logicphire.com.br/. Ao acessar ao site
            <a href="https://logicphire.com.br/"> logicphire</a>, concorda em
            cumprir estes termos de serviço, todas as leis e regulamentos
            aplicáveis e concorda que é responsável pelo cumprimento de todas as
            leis locais aplicáveis. Se você não concordar com algum desses
            termos, está proibido de usar ou acessar este site. Os materiais
            contidos neste site são protegidos pelas leis de direitos autorais e
            marcas comerciais aplicáveis.
            <h5>Licença:</h5>
            Exceto em casos em que for indicado o contrário, Logicphire e seus
            licenciados têm direito à propriedade intelectual de todo o material
            postado no Logicphire.com.br. Todos os direitos à propriedade
            intelectual são reservados. Você não tem permissão para:
            <ul>
                <li>Copiar ou republicar materiais da Logicphire.com.br</li>
                <li>
                    Vender, alugar ou sublocar materiais da Logicphire.com.br
                </li>
                <li>
                    Reproduzir, duplicar ou copiar materiais da
                    Logicphire.com.br
                </li>
                <li>Redistribuir conteúdos da Logicphire.com.br</li>
            </ul>
            Este Acordo terá efeito a partir da data presente. Partes deste site
            oferecem ao usuário a oportunidade de postar e discutir opiniões e
            informações em determinadas áreas. Logicphire filtra, edita, publica
            e revisa Comentários antes que eles sejam apresentados no site.
            Comentários refletem as opiniões da pessoa que os posta. Na extensão
            em que as leis aplicáveis permitem, Logicphire não se responsabiliza
            legalmente pelos Comentários ou quaisquer danos, riscos ou despesas
            causadas ou sofridas como resultado do uso, e/ou postagem e/ou
            aparência dos Comentários deste site. Logicphire reserva para si o
            direito de recomer quaisquer Comentários que possa ser considerado
            inapropriado, ofensivo ou quebre os Termos e Condições deste
            contrato. Você declara e garante que:
            <ul>
                <li>
                    Você tem o direito de postar Comentários em nosso site e tem
                    todas as licenças e consentimentos para tal;
                </li>
                <li>
                    Os Comentários não invadem qualquer propriedade intelectual,
                    incluindo direitos autorais, patentes ou marcas registradas
                    de terceiros;
                </li>
                <li>
                    Os Comentários não contém material difamatório, injurioso,
                    ofensivo, indecente ou de alguma forma ilícito, que é
                    invasão de privacidade.
                </li>
                <li>
                    Os Comentários não serão usados para solicitar ou promover
                    negócios ou apresentar atividades comerciais ou atividades
                    ilícitas.
                </li>
            </ul>
            Você por meio deste concede a Logicphire a licença não-exclusiva de
            usar, reproduzir, editar e autorizar outros a usar, reproduzir ou
            editar qualquer um de seus Comentários em qualquer e todas as
            formas, formatos e mídias.
            <h4>Limitações</h4>
            Em nenhum caso o Logicphire ou seus fornecedores serão responsáveis
            por quaisquer danos (incluindo, sem limitação, danos por perda de
            dados ou lucro ou devido a interrupção dos negócios) decorrentes do
            uso ou da incapacidade de usar os materiais em Logicphire, mesmo que
            Logicphire ou um representante autorizado da Logicphire tenha sido
            notificado oralmente ou por escrito da possibilidade de tais danos.
            Como algumas jurisdições não permitem limitações em garantias
            implícitas, ou limitações de responsabilidade por danos consequentes
            ou incidentais, essas limitações podem não se aplicar a você. &nbsp;
            <h4>Precisão dos materiais</h4>
            Os materiais exibidos no site da Logicphire podem incluir erros
            técnicos, tipográficos ou fotográficos. Logicphire não garante que
            qualquer material em seu site seja preciso, completo ou atual.
            Logicphire pode fazer alterações nos materiais contidos em seu site
            a qualquer momento, sem aviso prévio. No entanto, Logicphire não se
            compromete a atualizar os materiais.
            <h5>Criação de links para nosso conteúdo</h5>
            As seguintes organizações podem criar links para nosso Site sem a
            necessidade de aprovação prévia por escrito:
            <ul>
                <li>Agências governamentais;</li>
                <li>Mecanismos de busca;</li>
                <li>Organizações de mídia;</li>
                <li>
                    Distribuidores de diretórios online podem inserir links para
                    nosso Site na mesma maneira que inserem hiperlinks para
                    Sites de outras empresas listadas; e
                </li>
                <li>
                    Empresas credenciadas, exceto organizações angariantes sem
                    fins lucrativos e grupos de arrecadação de fundos para
                    instituições de caridade, que não podem inserir links para
                    nosso Site sem aprovação prévia.
                </li>
            </ul>
            Essas organizações podem postar links para nossa página inicial,
            nossas publicações ou outras informações do Site, contanto que o
            link: (a) não seja, de forma alguma, enganoso; (b) não insinue
            falsamente a existência de uma relação de patrocínio, endosso, ou
            aprovação nossa a produtos e/ou serviços; e (c) seja apropriado ao
            contexto em que está sendo inserido. Nós podemos considerar e
            aprovar solicitações de link feitas pelos seguintes tipos de
            organizações:
            <ul>
                <li>
                    fontes de informação bem conhecidas sobre produtos e
                    negócios;
                </li>
                <li>sites comunitários ponto.com;</li>
                <li>
                    associações e outros grupos que representem instituições de
                    caridade;
                </li>
                <li>distribuidores de diretórios online;</li>
                <li>portais de internet;</li>
                <li>firmas de contabilidade, advocacia e consultoria; e</li>
                <li>instituições educacionais e associações de classe.</li>
            </ul>
            Nós vamos aprovar solicitações de link feitas pelos tipos de
            organização listados acima se julgarmos que: (a) o link não é
            desfavorável para nossa imagem e/ou para a imagem de empresas
            credenciadas; (b) a organização solicitante não possui histórico
            negativo conosco; (c) o benefício que ganhamos com a visibilidade do
            link compensa a ausência do Logicphire; e (d) o link será inserido
            em um contexto geral informativo. Essas organizações podem postar
            links para nossa página inicial contanto que o link: (a) não seja,
            de forma alguma, enganoso; (b) não insinue falsamente a existência
            de uma relação de patrocínio, endosso, ou aprovação de produtos e/ou
            serviços da nossa parte; e (c) seja apropriado ao contexto em que
            está sendo inserido. Se você representa uma das organizações
            listadas no parágrafo 2 acima e está interessado em postar um link
            para nosso site, você deve nos informar de seu interesse enviando um
            e-mail para contato@logicphire.com.br. Por favor, inclua seu some, o
            nome da sua organização e suas informações de contato, assim como a
            URL do seu site, uma lista das URLs do nosso site que você pretende
            usar como links, e uma lista dos sites nos quais você pretende
            publicar nossas URLs. Aguarde entre 2 e 3 semanas para receber uma
            resposta. Organizações aprovadas poderão publicar links para nosso
            Site das seguintes formas:
            <ul>
                <li>Usando nosso nome corporativo; ou</li>
                <li>Usando a URL para onde o link redireciona; ou</li>
                <li>
                    Usando qualquer outra descrição do nosso Site que faça
                    sentido dentro do contexto e do formato do conteúdo onde o
                    link está sendo inserido.
                </li>
            </ul>
            Não será permitido o uso ou a publicação de links para o logo e/ou
            outros elementos visuais da Logicphire sem um acordo de licença para
            o uso da marca registrada.
            <h5>Responsabilização pelo Conteúdo:</h5>
            Não seremos responsabilizados legalmente por qualquer conteúdo que
            apareça em nosso Site. Você concorda em nos proteger e nos defender
            contra todas as acusações que forem levantadas contra nosso Site.
            Nenhum link deve aparecer em qualquer Site que possa ser
            interpretado como difamatório, obsceno, criminoso, ou que infrinja,
            viole ou defenda a violação dos direitos de terceiros.
            <h5>Reserva de Direitos:</h5>
            Reservamos nosso direito de solicitar que você remova todos os links
            ou quaisquer links que redirecionem para nosso site. Você concorda
            em remover imediatamente todos os links para nosso site assim que a
            remoção for solicitada. Também reservamos nosso direito de corrigir
            e alterar estes termos e condições a qualquer momento. Ao publicar
            continuadamente links para nosso site, você concorda a seguir estes
            termos e condições sobre links.
            <h5>Remoção de links postados em nosso site:</h5>
            Se você encontrar qualquer link em nosso Site que seja de qualquer
            forma ofensivo, você tem a liberdade de entrar em contato conosco e
            nos informar do problema a qualquer momento. Vamos considerar as
            solicitações de remoção de links, mas não somos obrigados a remover
            quaisquer links do nosso site nem a responder diretamente à sua
            solicitação. Nós não garantimos que as informações contidas neste
            site são corretas. Nós não garantimos integralidade ou exatidão do
            conteúdo. Não garantimos que o site se manterá disponível ou que o
            material do site se manterá atualizado.
            <h5>Declaração de Isenção de Responsabilidade:</h5>
            No máximo possível permitido por lei, nós excluímos todas as
            representações, garantias e condições relativas ao nosso site e ao
            uso deste site. Nada nesta declaração de isenção de responsabilidade
            vai:
            <ul>
                <li>
                    limitar ou excluir nossa responsabilidade ou sua
                    responsabilidade por mortes ou danos pessoais;
                </li>
                <li>
                    limitar ou excluir nossa responsabilidade ou sua
                    responsabilidade por fraudes ou deturpações fraudulentas;
                </li>
                <li>
                    limitar nossa responsabilidade ou sua responsabilidade de
                    quaisquer maneiras que não forem permitidas sob a lei;
                    excluir quaisquer responsabilidades suas ou nossas que não
                    podem ser excluídas de acordo com a lei aplicável.
                </li>
            </ul>
            As limitações e proibições de responsabilização listadas nesta Seção
            e em outras partes desta declaração: (a) estão sujeitas ao parágrafo
            anterior; e (b) regem todas as responsabilizações que surgirem sob a
            declaração, incluindo responsabilizações surgidas em contrato, em
            delitos e em quebra de obrigações legais. Enquanto o site e as
            informações e serviços do site forem oferecidos gratuitamente, nós
            não seremos responsáveis por perdas e danos de qualquer natureza.
            &nbsp;
        </Terms>
    </Container>
);

export default UseTerms;
