/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { useEffect, useState } from "react";

import { AiOutlineStar } from "react-icons/ai";
import Person from "../../assets/images/person.png";
import { getAllActiveDepositions } from "../../controller/FirebaseController/Depositions/findDepositions";

import { Container } from "./styles";

const Depoiments = () => {
    const [depositions, setDepositions] = useState([]);

    useEffect(() => {
        getAllActiveDepositions()
            .then((result) => {
                setDepositions(result);
            })
            .catch();
    }, []);

    const renderEvaluation = (evaluation) =>
        Array.from({ length: evaluation }, (_, index) => index + 1);

    const renderDepoimentText = (text) => {
        if (text.length > 220) {
            return `${text.substring(0, 210)}...`;
        }

        return text;
    };

    return (
        <Container>
            <div className="depoiments">
                <p className="subtextDepoiments">Depoimentos</p>
                <h4 className="textDepoiments">
                    Veja o que estão dizendo sobre nós
                </h4>
                <div
                    id="demo"
                    className="carousel slide"
                    data-bs-ride="carousel"
                >
                    <div className="carousel-indicators ">
                        <button
                            type="button"
                            data-bs-target="#demo"
                            data-bs-slide-to="0"
                            className="active indicatorsbottom"
                        />
                        <button
                            type="button"
                            data-bs-target="#demo"
                            data-bs-slide-to="1"
                            className=" indicatorsbottom"
                        />
                    </div>

                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <section
                                id="testimonials"
                                className="testimonials section-bg"
                            >
                                <div className="row">
                                    {depositions.map((testimony, index) => {
                                        if (index <= 2) {
                                            return (
                                                <div
                                                    className="col-sm-4"
                                                    key={testimony.id}
                                                >
                                                    <div className="card spacecard">
                                                        <div className="card-body">
                                                            <div className="testimonial-item bg-white rounded p-4 internal-card">
                                                                <div className="d-flex align-items-center mb-4">
                                                                    <img
                                                                        className="flex-shrink-0 rounded-circle border p-1"
                                                                        src={
                                                                            Person
                                                                        }
                                                                        alt=""
                                                                    />
                                                                    <div className="ms-4">
                                                                        <h5 className="mb-1">
                                                                            {
                                                                                testimony.userName
                                                                            }
                                                                        </h5>

                                                                        <div className="estrelas">
                                                                            {renderEvaluation(
                                                                                testimony.evaluation
                                                                            ).map(
                                                                                (
                                                                                    evaluation
                                                                                ) => (
                                                                                    <label
                                                                                        htmlFor="cm_star-1"
                                                                                        key={
                                                                                            evaluation
                                                                                        }
                                                                                    >
                                                                                        <AiOutlineStar />
                                                                                    </label>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p className="mb-0">
                                                                    {renderDepoimentText(
                                                                        testimony.description
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }

                                        return null;
                                    })}
                                </div>
                            </section>
                        </div>
                        <div className="carousel-item">
                            <div className="row">
                                {depositions.map((testimony, index) => {
                                    if (index > 2) {
                                        return (
                                            <div
                                                className="col-sm-4"
                                                key={testimony.id}
                                            >
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="testimonial-item bg-white rounded p-4">
                                                            <div className="d-flex align-items-center mb-4">
                                                                <img
                                                                    className="flex-shrink-0 rounded-circle border p-1"
                                                                    src={Person}
                                                                    alt=""
                                                                />
                                                                <div className="ms-4">
                                                                    <h5 className="mb-1">
                                                                        {
                                                                            testimony.userName
                                                                        }
                                                                    </h5>

                                                                    <div className="estrelas">
                                                                        {renderEvaluation(
                                                                            testimony.evaluation
                                                                        ).map(
                                                                            (
                                                                                evaluation
                                                                            ) => (
                                                                                <label
                                                                                    htmlFor="cm_star-1"
                                                                                    key={
                                                                                        evaluation
                                                                                    }
                                                                                >
                                                                                    <AiOutlineStar />
                                                                                </label>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p className="mb-0">
                                                                {
                                                                    testimony.description
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }

                                    return null;
                                })}
                            </div>
                        </div>
                    </div>

                    <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#demo"
                        data-bs-slide="prev"
                    >
                        <span className="carousel-control-prev-icon" />
                    </button>
                    <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#demo"
                        data-bs-slide="next"
                    >
                        <span className="carousel-control-next-icon" />
                    </button>
                </div>
            </div>
        </Container>
    );
};

export default Depoiments;
