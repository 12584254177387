import styled from "styled-components";

export const Container = styled.div``;

export const Target = styled.div`
    width: 100%;
    height: 28px;

    margin-top: -48px;

    position: absolute;

    background-color: ${({ theme }) => theme.colors.background};
`;
