/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { RouteURLs } from "../../Routes/RouteURLs";
import ContactModal from "../ContactModal";

import LogoLogicphire from "../../assets/images/logoLogicphire.webp";

import { Container } from "./styles";

const Header = () => {
    const navigate = useNavigate();

    const handleRedirectProjectsPage = () => {
        navigate(RouteURLs.projects);
    };

    return (
        <div className="fluid">
            <Container fluid>
                <ContactModal />
                <div className="colornav" id="home">
                    <nav className="navbar navbar-expand-sm navbar-dark justify-content-end ">
                        <div className="container-fluid ">
                            <Link className="navbar-brand" to={RouteURLs.home}>
                                <img
                                    src={LogoLogicphire}
                                    alt="Logicphire - logo"
                                />
                            </Link>
                            <button
                                className="navbar-toggler"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapsibleNavbar"
                            >
                                <span className="navbar-toggler-icon" />
                            </button>
                            <div
                                className="collapse navbar-collapse justify-content-end"
                                id="collapsibleNavbar"
                            >
                                <ul className="navbar-nav ">
                                    <li className="nav-item ">
                                        <Link
                                            className="nav-link"
                                            to={RouteURLs.home}
                                            style={{ color: "white" }}
                                        >
                                            Início
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className="nav-link"
                                            href="#AboutUs"
                                            style={{ color: "white" }}
                                        >
                                            Sobre nós
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className="nav-link"
                                            href="#services"
                                            style={{ color: "white" }}
                                        >
                                            Serviços
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link"
                                            to={RouteURLs.projects}
                                            style={{ color: "white" }}
                                            onClick={handleRedirectProjectsPage}
                                        >
                                            Projetos
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className="nav-link"
                                            href="#"
                                            style={{ color: "white" }}
                                            data-bs-toggle="modal"
                                            data-bs-target="#contactModal"
                                        >
                                            Contato
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </Container>
        </div>
    );
};

export const HeaderSimple = () => (
    <Container>
        <div className="divlogo">
            <Link to={RouteURLs.home}>
                <img
                    className="sizelogo"
                    src={LogoLogicphire}
                    alt="Logicphire - logo"
                />
            </Link>
        </div>
    </Container>
);

export default Header;
