import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toast = (type, message = "Message Not Found") => {
    switch (type) {
        case "info":
            return toast.info(`ℹ️ ${message}`);

        case "success":
            return toast.success(`✅ ${message}`);

        case "warning":
            return toast.warning(`⚠️ ${message}`);

        case "error":
            return toast.error(`🚫 ${message}`);

        case "dark":
            return toast.dark(`🕵🏼‍♂️ ${message}`);

        default:
            return toast(message);
    }
};

export default Toast;
