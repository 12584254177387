export default {
    title: "dark",

    fonts: {
        primary: "Fredoka One",
        secondary: "Varela Round",
    },

    colors: {
        primary: "#47059c",
        secondary: "#a11079",

        background: "#f5f5f5",
        text: "#333",

        white: "#fff",
        black: "#000",

        hoverPrimary: "#38047c",
        hoverSecondary: "#d1159c",
    },
};
