import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        font-family: ${(props) => props.theme.fonts.secondary};

        margin: 0;
        box-sizing: border-box;
    }

    html {
        @media (max-width: 1080px) {
            font-size: 90.75%;
        }

        @media (max-width: 720px) {
            font-size: 87.5%;
        }
    }

    body {
        color: ${(props) => props.theme.colors.text};
        background: ${(props) => props.theme.colors.background};
        -webkit-font-smoothing: antialiased;
    }

    body,
    input,
    button,
    textarea {
        font-weight: 400;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 600;
    }

    button {
        cursor: pointer;
    }

    [disabled] {
        opacity: 0.6;
        cursor: not-allowed;
    }
`;
