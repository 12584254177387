import React from "react";

import { Container, Target } from "./styles";

const InstagramFeed = () => (
    <>
        <Container data-mc-src="ea5808a8-8564-4471-8e8c-67337e962762#instagram" />
        <Target />
    </>
);

export default InstagramFeed;
