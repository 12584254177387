/* eslint-disable operator-linebreak */

import React, { useState } from "react";

import Toast from "../Toast";
import {
    validateEmail,
    validatePhoneNumber,
} from "../../controller/ValidateFileds/ValidateEmailController";
import { SaveBudget } from "../../controller/FirebaseController/Budget/createBudget";

import LogoLogicphire from "../../assets/images/logoLogicphire.webp";

import { Container } from "./styles";

const BudgetModal = ({ selectedProject }) => {
    const [loadingBudget, setLoadingBudget] = useState(false);
    const [budgetName, setBudgetName] = useState("");
    const [budgetSurname, setBudgetSurname] = useState("");
    const [budgetEmail, setBudgetEmail] = useState("");
    const [budgetPhone, setBudgetPhone] = useState("");
    const [budgetPlatform, setBudgetPlatform] = useState("");
    const [budgetMessage, setBudgetMessage] = useState("");

    const handleSubmitBudget = async (event) => {
        event.preventDefault();

        if (
            budgetName !== "" &&
            budgetSurname !== "" &&
            budgetEmail !== "" &&
            budgetPhone !== "" &&
            budgetPlatform !== "" &&
            budgetMessage !== ""
        ) {
            if (validateEmail(budgetEmail)) {
                if (validatePhoneNumber(budgetPhone)) {
                    setLoadingBudget(true);

                    const result = await SaveBudget(
                        budgetName,
                        budgetSurname,
                        budgetEmail,
                        budgetPhone,
                        budgetPlatform,
                        budgetMessage,
                        selectedProject
                    );

                    if (result) {
                        Toast(
                            "success",
                            "Orçamento enviado, em breve a Logic irá entrar em contato com você. Obrigado!"
                        );

                        setBudgetName("");
                        setBudgetSurname("");
                        setBudgetEmail("");
                        setBudgetPhone("");
                        setBudgetPlatform("");
                        setBudgetMessage("");
                    } else {
                        Toast(
                            "error",
                            "Ops, algo de errado não deu certo, favor tente novamente mais tarde!"
                        );
                    }

                    setLoadingBudget(false);
                } else {
                    Toast("warning", "Campo de Telefone, incorreto!");
                }
            } else {
                Toast("warning", "Campo de Email, incorreto!");
            }
        } else {
            Toast("warning", "Todos os campos devem ser preenchidos!");
        }
    };

    return (
        <Container>
            <div
                className="modal fade"
                id="budgetModal"
                tabIndex="-1"
                aria-labelledby="budgetModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header headermodal">
                            <img
                                className="logomodal"
                                src={LogoLogicphire}
                                alt="Logicphire - logo"
                            />
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <form action="/action_page.php">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mb-3 mt-3">
                                            <label
                                                htmlFor="text"
                                                className="form-label"
                                            >
                                                Nome:
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="nome"
                                                placeholder="Nome"
                                                name="nome"
                                                required
                                                value={budgetName}
                                                onChange={(event) =>
                                                    setBudgetName(
                                                        event.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3 mt-3">
                                            <label
                                                htmlFor="text"
                                                className="form-label"
                                            >
                                                Sobrenome:
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="sobrenome"
                                                placeholder="Sobrenome"
                                                name="sobrenome"
                                                required
                                                value={budgetSurname}
                                                onChange={(event) =>
                                                    setBudgetSurname(
                                                        event.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                {selectedProject ? (
                                    <>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="mb-3 mt-3">
                                                    <label
                                                        htmlFor="email"
                                                        className="form-label"
                                                    >
                                                        Email:
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="email"
                                                        placeholder="nome@exemplo.com"
                                                        name="email"
                                                        required
                                                        value={budgetEmail}
                                                        onChange={(event) =>
                                                            setBudgetEmail(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="mb-3 mt-3">
                                                    <label
                                                        htmlFor="text"
                                                        className="form-label"
                                                    >
                                                        Telefone:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="telefone"
                                                        placeholder="(99) 99999-9999"
                                                        name="telefone"
                                                        required
                                                        value={budgetPhone}
                                                        onChange={(event) =>
                                                            setBudgetPhone(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="mb-3 mt-3">
                                                    <label
                                                        htmlFor="text"
                                                        className="form-label"
                                                    >
                                                        Plataforma:
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        value={budgetPlatform}
                                                        onChange={(event) =>
                                                            setBudgetPlatform(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <option value="">
                                                            Selecionar
                                                        </option>
                                                        <option value="Site">
                                                            Web Site
                                                        </option>
                                                        <option value="Desktop">
                                                            Desktop (Computador)
                                                        </option>
                                                        <option value="Mobile">
                                                            Mobile (Aplicativos
                                                            para Celular)
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="mb-3 mt-3">
                                                    <label
                                                        htmlFor="text"
                                                        className="form-label"
                                                    >
                                                        Projeto Base:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="baseProject"
                                                        name="baseProject"
                                                        value={selectedProject}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="mb-3 mt-3">
                                            <label
                                                htmlFor="email"
                                                className="form-label"
                                            >
                                                Email:
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                placeholder="nome@exemplo.com"
                                                name="email"
                                                required
                                                value={budgetEmail}
                                                onChange={(event) =>
                                                    setBudgetEmail(
                                                        event.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="mb-3 mt-3">
                                                    <label
                                                        htmlFor="text"
                                                        className="form-label"
                                                    >
                                                        Telefone:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="telefone"
                                                        placeholder="(99) 99999-9999"
                                                        name="telefone"
                                                        required
                                                        value={budgetPhone}
                                                        onChange={(event) =>
                                                            setBudgetPhone(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="mb-3 mt-3">
                                                    <label
                                                        htmlFor="text"
                                                        className="form-label"
                                                    >
                                                        Plataforma:
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        value={budgetPlatform}
                                                        onChange={(event) =>
                                                            setBudgetPlatform(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <option value="">
                                                            Selecionar
                                                        </option>
                                                        <option value="Site">
                                                            Web Site
                                                        </option>
                                                        <option value="Desktop">
                                                            Desktop (Computador)
                                                        </option>
                                                        <option value="Mobile">
                                                            Mobile (Aplicativos
                                                            para Celular)
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="mb-3 mt-3">
                                    <label
                                        htmlFor="text"
                                        className="form-label"
                                    >
                                        Descreva seu projeto:
                                    </label>
                                    <div className="form-floating">
                                        <textarea
                                            className="form-control"
                                            id="comment"
                                            name="text"
                                            rows="5"
                                            required
                                            value={budgetMessage}
                                            onChange={(event) =>
                                                setBudgetMessage(
                                                    event.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary buttonSend"
                                    onClick={handleSubmitBudget}
                                >
                                    {loadingBudget ? (
                                        <div
                                            className="spinner-border"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </div>
                                    ) : (
                                        "Solicitar Orçamento"
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default BudgetModal;
