/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";

import { IoIosArrowForward } from "react-icons/io";
import { BsWhatsapp, BsInstagram } from "react-icons/bs";
import { Link } from "react-router-dom";

import LogoLogicphire from "../../assets/images/logoLogicphire.webp";

import { Container } from "./styles";
import { RouteURLs } from "../../Routes/RouteURLs";

const Footer = () => (
    <Container>
        <div className="colorFooter">
            <div className="footer">
                <div className="row spacerowFooter">
                    <div className="col-sm-4">
                        <img
                            className="logoFooter"
                            src={LogoLogicphire}
                            alt="Logicphire - logo"
                        />
                        <p className="descriptionFooter">
                            Uma empresa voltada as boas praticas do
                            desenvolvimento de forma moderna, simples, rapida e
                            clara, onde seu foco é o melhor serviço para o
                            cliente.
                        </p>
                    </div>
                    <div className="col-sm-4">
                        <h5 className="links">Links uteis</h5>
                        <ul className="nav flex-column ">
                            <li className="nav-item ">
                                <a className="nav-link linksnav" href="#home">
                                    <IoIosArrowForward />
                                    Início
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link linksnav"
                                    href="#AboutUs"
                                >
                                    <IoIosArrowForward />
                                    Sobre nós
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link linksnav"
                                    href="#services"
                                >
                                    <IoIosArrowForward />
                                    Serviços
                                </a>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link linksnav "
                                    to={RouteURLs.projects}
                                >
                                    <IoIosArrowForward />
                                    Projetos
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link linksnav"
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#contactModal"
                                >
                                    <IoIosArrowForward />
                                    Contato
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-4">
                        <h5 className="linksContato">Contate-nos</h5>
                        <p className="descriptionFooterContato">
                            Telefone: (85) 99793-5406
                        </p>
                        <p className="descriptionFooterContato">
                            E-mail: logicphire@logicphire.com.br
                        </p>
                        <div className="icons">
                            <a
                                href="https://www.instagram.com/logicphire/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <div className="icon">
                                    <BsInstagram />
                                </div>
                            </a>

                            <a
                                href="https://api.whatsapp.com/send/?phone=5585997935406&text=Ol%C3%A1%21+Estou+em+busca+de+realizar+um+sonho%21&app_absent=0"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <div className="icon">
                                    <BsWhatsapp />
                                </div>
                            </a>
                            {/* <a href="#">
                                <div className="icon">
                                    <BsLinkedin />
                                </div>
                            </a> */}
                        </div>
                    </div>
                </div>
                <div className="linefooter" />
                <p className="copyright">© Copyright Logicphire 2023.</p>
                <p className="copyright">
                    Conheça nossos{" "}
                    <Link to={RouteURLs.useTerms}>termos de uso</Link> e nossa{" "}
                    <Link to={RouteURLs.privacyPolicy}>
                        politica de privacidade
                    </Link>
                </p>
            </div>
        </div>
    </Container>
);

export default Footer;
