import { useMemo } from "react";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";

import removeThemeStorage from "./controller/StorageController/themeToken";
import usePersistedState from "./utils/usePersistedState";
import ToggleThemeContext from "./utils/ToggleThemeContext";

import Routes from "./Routes";

import light from "./styles/themes/light";
import dark from "./styles/themes/dark";
import GlobalStyle from "./styles/global";

const App = () => {
    removeThemeStorage();

    const [theme, setTheme] = usePersistedState("@theme", light);

    const toggleTheme = useMemo(() => ({
        toggleThemeContext: () => {
            setTheme(theme.title === "light" ? light : dark);
        },
    }));

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <ToastContainer />
            <ToggleThemeContext.Provider value={toggleTheme}>
                <Routes />
            </ToggleThemeContext.Provider>
        </ThemeProvider>
    );
};

export default App;
