import { collection, doc, setDoc } from "firebase/firestore";
import { v4 as uuidV4 } from "uuid";

import { firestore } from "../../../services/Firebase";

const CollectionName = "contact";

export const SaveContact = async (userName, email, phone, message) => {
    try {
        const contactRef = collection(firestore, CollectionName);

        const contactId = uuidV4();

        await setDoc(doc(contactRef, contactId), {
            id: contactId,
            userName,
            email,
            phone,
            message,
            readed: false,
            created_at: new Date(),
            updated_at: new Date(),
        });

        return true;
    } catch (error) {
        console.log(error);

        return false;
    }
};
