import styled from "styled-components";

export const Container = styled.div`
    .modal-title {
        color: ${({ theme }) => theme.colors.primary};
    }

    .modal-body {
        color: ${({ theme }) => theme.colors.text};
    }

    .buttonSend {
        background-color: ${({ theme }) => theme.colors.secondary};

        border-color: ${({ theme }) => theme.colors.secondary};

        &:hover {
            background-color: ${({ theme }) => theme.colors.hoverSecondary};
        }
    }

    .headermodal {
        background-color: ${({ theme }) => theme.colors.primary};
    }

    .logomodal {
        width: 10%;

        @media only screen and (max-width: 600px) {
            width: 40%;
        }
    }

    .bordermodalcontact {
        border-right: 1px solid #ccc;
    }

    .modalcontacttext {
        margin-top: 2%;
    }

    .textscontact {
        margin-bottom: 8%;
    }
    h6 {
        color: ${({ theme }) => theme.colors.primary};
    }
`;
