import { collection, doc, setDoc } from "firebase/firestore";
import { v4 as uuidV4 } from "uuid";

import { firestore } from "../../../services/Firebase";

const CollectionName = "budget";

export const SaveBudget = async (
    userName,
    userSurname,
    email,
    phone,
    platform,
    message,
    selectedProject
) => {
    try {
        const budgetRef = collection(firestore, CollectionName);

        const budgetId = uuidV4();

        await setDoc(doc(budgetRef, budgetId), {
            id: budgetId,
            userName,
            userSurname,
            email,
            phone,
            platform,
            message,
            selectedProject: selectedProject || "",
            readed: false,
            created_at: new Date(),
            updated_at: new Date(),
        });

        return true;
    } catch (error) {
        console.log(error);

        return false;
    }
};
