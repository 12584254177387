import { BrowserRouter, Routes, Route } from "react-router-dom";

import { RouteURLs } from "./RouteURLs";

import Home from "../Pages/Home";
import Projects from "../Pages/projects";
import UseTerms from "../Pages/UseTerms";
import PrivacyPolicy from "../Pages/PrivacyPolicy";

import NotFound from "../Pages/NotFound";

const RoutesApp = () => (
    <BrowserRouter>
        <Routes>
            <Route path={RouteURLs.home} element={<Home />} />
            <Route path={RouteURLs.projects} element={<Projects />} />
            <Route path={RouteURLs.useTerms} element={<UseTerms />} />
            <Route path={RouteURLs.privacyPolicy} element={<PrivacyPolicy />} />

            <Route path="*" element={<NotFound />} />
        </Routes>
    </BrowserRouter>
);

export default RoutesApp;
