import React, { useState, useEffect } from "react";
import { AiOutlineStar } from "react-icons/ai";

import BudgetModal from "../../components/BudgetModal";
import { getAllPorjects } from "../../controller/FirebaseController/Projects/findProject";

import Banner from "../../assets/images/minichef.jpg";

import { Container } from "./styles";
import { HeaderSimple } from "../../components/Header";

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [projectName, setProjectName] = useState("");

    useEffect(() => {
        getAllPorjects()
            .then((data) => {
                setProjects(data);
            })
            .catch();
    }, []);

    const handleRedirectProjectWebPage = (url) => {
        window.open(url, "_blank");
    };

    const generateTextButtonViewProject = (platform) => {
        switch (platform) {
            case "Mobile (Aplicativo para Celular)":
                return "Ver na Google Play";

            case "Web Site":
                return "Ver Site";

            default:
                return "Ver Site";
        }
    };

    const includeProjacetNameBudgetModal = (name) => {
        setProjectName(name);
    };

    const renderEvaluation = (evaluation) =>
        Array.from({ length: evaluation }, (_, index) => index + 1);

    return (
        <Container>
            <BudgetModal selectedProject={projectName} />
            <HeaderSimple />
            <div className="banner">
                <img
                    className="img-fluid sizebanner"
                    src={Banner}
                    alt="Banner"
                />
            </div>
            <div className=" container ">
                <p className="subtitleprojects">Projetos</p>
                <h5 className="titleprojects">Conheçam nossos projetos</h5>
            </div>
            <div className=" container ">
                <div className="row">
                    {projects.map((project) => (
                        <div className="col-sm-4" key={project.id}>
                            <div className="card spacecard">
                                <div className="card-body">
                                    <img
                                        className="img-fluid imagecard card-img-top"
                                        src={project.imageLink}
                                        alt="Imagem sobre nós"
                                    />

                                    <div className="buttonvision">
                                        <button
                                            type="button"
                                            className="btn  vision"
                                            onClick={() =>
                                                handleRedirectProjectWebPage(
                                                    project.link
                                                )
                                            }
                                        >
                                            {generateTextButtonViewProject(
                                                project.platform
                                            )}
                                        </button>
                                    </div>
                                    <div className="spacetexts">
                                        <div className="row">
                                            <div className="col-sm-8">
                                                <strong>
                                                    {project.projectName}
                                                </strong>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="estrelas">
                                                    {renderEvaluation(
                                                        project.evaluation
                                                    ).map((evaluation) => (
                                                        <label
                                                            htmlFor="cm_star-1"
                                                            key={evaluation}
                                                        >
                                                            <AiOutlineStar />
                                                        </label>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <p className="subtitlecard">
                                            {project.platform}
                                        </p>
                                        <p className="paragraphycard">
                                            {project.description}
                                        </p>
                                    </div>
                                </div>
                                <div className="card-footer ">
                                    <div className="buttoncard">
                                        <button
                                            type="button"
                                            className="btn btn-primary budgetButton"
                                            data-bs-toggle="modal"
                                            data-bs-target="#budgetModal"
                                            onClick={() => {
                                                includeProjacetNameBudgetModal(
                                                    project.projectName
                                                );
                                            }}
                                        >
                                            Usar Modelo Para Orçamento
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    );
};

export default Projects;
