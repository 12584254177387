import styled from "styled-components";

export const Container = styled.div`
    .divlogo {
        background-color: ${(props) => props.theme.colors.primary};
        margin: 0 auto;
        text-align: center;
        padding-top: 1%;
    }

    .sizelogo {
        @media only screen and (max-width: 600px) {
            width: 40%;
        }
    }

    .banner {
        margin: 0 auto;
        text-align: center;
        margin-top: 3%;
    }

    .subtitleprojects {
        margin-top: 4%;
    }
    .titleprojects {
        color: ${({ theme }) => theme.colors.primary};
        margin-bottom: 3%;
    }

    .card-body {
        height: 450px;

        padding: 0;
        margin: 0;
    }

    .buttonvision {
        margin: 0 auto;
        text-align: right;
        margin-bottom: 8%;
        margin-top: -13%;
        margin-right: 2%;

        @media only screen and (max-width: 600px) {
            margin-top: -13%;
        }
    }

    .vision {
        background-color: #ccc;
        opacity: 0.8;
        color: black;
    }

    .vision:hover {
        background-color: #333;
        opacity: 0.8;
        color: white;
    }
    .estrelas input[type="radio"] {
        display: none;
    }
    .estrelas label i.fa {
        font-size: 2.5em;
    }
    .estrelas label i.fa:before {
        content: "\f005";
        color: #fc0;
    }
    .estrelas input[type="radio"]:checked ~ label i.fa:before {
        color: #ccc;
    }
    .spacetexts {
        padding: 15px;
    }

    .subtitlecard {
        color: #808080;
        font-size: 13px;
    }

    .paragraphycard {
        text-align: justify;
        font-size: 14px;
        padding-bottom: 8%;
    }

    button.budgetButton {
        width: 80%;
        height: 40px;
        border: none;

        background-color: ${({ theme }) => theme.colors.secondary};
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);

        &:hover {
            background-color: ${({ theme }) => theme.colors.hoverSecondary};
        }

        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }

    .buttoncard {
        margin: 0 auto;
        text-align: center;
    }

    .spacecard {
        margin-bottom: 5%;
        box-shadow: 0px 1px 1px #a6a6a6;
    }

    .imagecard {
        box-shadow: 0px 1px 10px #a6a6a6;
    }
`;
