/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { BiCheckCircle } from "react-icons/bi";
import { BsWhatsapp, BsInstagram, BsLinkedin } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import InstagramFeed from "../../components/InstagramFeed";
import Header from "../../components/Header";
import Depoiments from "../../components/Depoiments";
import Footer from "../../components/Footer";
import BudgetModal from "../../components/BudgetModal";
import { RouteURLs } from "../../Routes/RouteURLs";

import AboutUsImage from "../../assets/images/AboutUsImage.jpg";
import DevelopmentImage from "../../assets/images/DevelopmentImage.png";
import DesignImage from "../../assets/images/DesignImage.png";
import SitesImage from "../../assets/images/SitesImage.png";
import DocumentationImage from "../../assets/images/DocumentationImage.png";
import ProjectsImage from "../../assets/images/ProjectsImage.png";
import Gilberto from "../../assets/images/gilberto.jpg";
import Italo from "../../assets/images/italo.jpg";

import {
    Container,
    ContentAboutUs,
    ContentBudget,
    ContentServices,
    ContentTeam,
} from "./styles";

const Home = () => {
    const navigate = useNavigate();

    const handleRedirectProjectsPage = () => {
        navigate(RouteURLs.projects);
    };

    const handleRedirectWebPage = (url) => {
        window.open(url, "_blank");
    };

    return (
        <Container fluid>
            <div className="startContainer">
                <Header />
                <div className="divContainerStart">
                    <h1>Uma nova forma de realizar seus sonhos</h1>
                    <h6>
                        Um desenvolvimento simples, rapido e seguro para o seu
                        negócio!
                    </h6>
                    <button
                        type="button"
                        className="btn buttomStart"
                        onClick={handleRedirectProjectsPage}
                    >
                        Projetos
                    </button>
                </div>
            </div>

            <ContentAboutUs fluid>
                <div className="row" id="AboutUs">
                    <div className="col-sm-6 contentImage">
                        <img src={AboutUsImage} alt="Imagem sobre nós" />
                    </div>
                    <div className="col-sm-6 contentInformation">
                        <p className="textAboutUs">Sobre Nós</p>
                        <h4>Conheça um pouco mais sobre nossa empresa</h4>
                        <p className="aboutUs">
                            Somos uma empresa de desenvolvimentos de software,
                            onde trabalhamos com designers, identidades visuais,
                            desenvolvimento de sites e muito mais.
                        </p>
                        <p className="aboutUsItem">
                            <BiCheckCircle size={25} />
                            Nossa visão é ser reconhecido como uma das empresas
                            de melhor desenvolvimento e criação do mercado,
                            sempre proporcionando um um trabalho simples e
                            seguro e de metologias próprias, usufruindo das
                            novas tecnologias que são lanças no mercado.
                        </p>
                        <p className="aboutUsItem">
                            <BiCheckCircle size={25} />
                            Nossa missão é ajudar pessoas e empresas com um
                            desenvolvimento no seu potencial maximo.
                        </p>
                        <p className="aboutUsItem">
                            <BiCheckCircle size={25} />
                            Nossos valores são, a inovação, confiança, respeito,
                            empatia, evolução, responsabilidade, integridade,
                            colaboração, diversidade; qualidade, humildade,
                            ética e transparência e resultados.
                        </p>
                        <p className="aboutUs">
                            Ficou interessado? Então veja logo abaixo os nossos
                            serviços e realize seus sonhos conosco!
                        </p>
                    </div>
                </div>
            </ContentAboutUs>
            <InstagramFeed />
            <ContentServices>
                <div className="row contentCard" id="services">
                    <div className="col-sm-4 contentInformation">
                        <p className="yourservices">Nossos serviços</p>
                        <h5>O que fazemos?</h5>
                        <div className="divider" />
                        <p className="description">
                            A Logicphire oferece aos seus clientes muitos
                            serviços, todos voltados para a comodidade,
                            praticidade, veracidade e eficácia para sua empresa
                            ou uso pessoal
                        </p>
                    </div>
                    <div className="col-sm-8 contentServices">
                        <div className="row">
                            <div className="col-sm-6">
                                <img
                                    src={DevelopmentImage}
                                    alt="Desenvolvimento de Softwares"
                                />
                                <h5>Desenvolvimento de Sofwares</h5>
                                <p>
                                    Desenvolvimento de aplicações Web, Moblie e
                                    Descktop robustas
                                </p>
                            </div>
                            <div className="col-sm-6">
                                <img
                                    src={DesignImage}
                                    alt="Serviço de Designer"
                                />
                                <h5>Designer</h5>
                                <p>
                                    Prototipagem de telas, criação de
                                    logomarcas, identidade visual.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <img
                                    src={SitesImage}
                                    alt="Sites e Portais Corporativos"
                                />

                                <h5>Sites e Portais Corporativos</h5>
                                <p>
                                    Responsável pelo desenvolvimento de
                                    documentações dos sistemas requeridos
                                </p>
                            </div>
                            <div className="col-sm-6">
                                <img
                                    src={DocumentationImage}
                                    alt="Documentação de Projetos"
                                />
                                <h5>Documentação de Projetos</h5>
                                <p>
                                    Responsável pelo desenvolvimento de
                                    documentações dos sistemas requeridos
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentServices>
            <ContentBudget>
                <div className="row cardBudget">
                    <div className="col-sm-6">
                        <p className="budget">Orçamentos</p>
                        <h4>Faça seu orçamento</h4>
                        <p className="description">
                            A Logicphire é uma empresa de desenvolvimento de
                            software onde trabalhamos com designers, identidades
                            visuais, desenvolvimento de sites e muito msi.
                            Gostou do nosso trabalho? Faça seu orçamento e tire
                            suas idéias do papel, tornando-as realizadade.
                        </p>
                        <div className="contentButtonsBugdet">
                            <button
                                type="button"
                                className="btn btn-primary budgetButton"
                                data-bs-toggle="modal"
                                data-bs-target="#budgetModal"
                            >
                                Orçamento
                            </button>
                            <BudgetModal />
                            <button
                                type="button"
                                className="btn btn-primary whatsapp"
                                onClick={() =>
                                    handleRedirectWebPage(
                                        "https://api.whatsapp.com/send/?phone=5585997935406&text=Ol%C3%A1%21+Estou+em+busca+de+realizar+um+sonho%21&app_absent=0"
                                    )
                                }
                            >
                                <BsWhatsapp />
                            </button>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <img
                            src={ProjectsImage}
                            alt="Orçamentos para projetos"
                        />
                    </div>
                </div>
            </ContentBudget>
            <ContentTeam>
                <div className="team">
                    <h4 className="titleteam">Conheça o time</h4>
                    <p className="paragraphyteam">
                        Conheça nossa equipe de desenvolvedores e saiba um pouco
                        mais sobre eles.
                    </p>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="card mb-3">
                                <div className="row g-0">
                                    <div className="col-md-4">
                                        <img
                                            src={Gilberto}
                                            className="img-fluid rounded-circle imgcardteam"
                                            alt="..."
                                        />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body textcard sizecard">
                                            <p className="card-text">
                                                <small className="text-muted">
                                                    Designer
                                                </small>
                                            </p>
                                            <h5 className="card-title">
                                                Gilberto Viana
                                            </h5>
                                            <p className="card-text">
                                                Design com experiencia nos
                                                padrões UX e UI, responsável
                                                pelas prototipação dos
                                                aplicativos e sites da
                                                Logicphire, criações artísticas,
                                                artes no geral da empresa e
                                                desenvolvedor Front-end.
                                            </p>
                                            <div className="icons">
                                                <a
                                                    href="https://www.instagram.com/gilberto_vlf/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <div className="icon">
                                                        <BsInstagram />
                                                    </div>
                                                </a>

                                                <a
                                                    href="https://api.whatsapp.com/send/?phone=5585997935406&text=Ol%C3%A1%21+Estou+em+busca+de+realizar+um+sonho%21&app_absent=0"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <div className="icon">
                                                        <BsWhatsapp />
                                                    </div>
                                                </a>
                                                <a
                                                    href="https://www.linkedin.com/in/gilberto-viana-designer-desenvolvedor/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <div className="icon">
                                                        <BsLinkedin />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="card mb-3">
                                <div className="row g-0">
                                    <div className="col-md-4">
                                        <img
                                            src={Italo}
                                            className="img-fluid rounded-circle imgcardteam"
                                            alt="..."
                                        />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body textcard sizecard">
                                            <p className="card-text">
                                                <small className="text-muted">
                                                    Programador
                                                </small>
                                            </p>
                                            <h5 className="card-title">
                                                Italo Moraes
                                            </h5>
                                            <p className="card-text">
                                                Desenvolvedor Full Stack React
                                                JS, React Native e Node JS.
                                                CTO da Logicphire, responsavel
                                                pelo desenvolvimento e qualidade
                                                de todos os softwares
                                                desenvolvidos.
                                            </p>
                                            <div className="icons">
                                                <a
                                                    href="https://www.instagram.com/italo_hoho/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <div className="icon">
                                                        <BsInstagram />
                                                    </div>
                                                </a>

                                                <a
                                                    href="https://api.whatsapp.com/send/?phone=5585987400122&text=Ol%C3%A1%21+Estou+em+busca+de+realizar+um+sonho%21&app_absent=0"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <div className="icon">
                                                        <BsWhatsapp />
                                                    </div>
                                                </a>
                                                <a
                                                    href="https://www.linkedin.com/in/italo-moraes-almeida-37628a163"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <div className="icon">
                                                        <BsLinkedin />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentTeam>
            <Depoiments />
            <Footer />
        </Container>
    );
};

export default Home;
