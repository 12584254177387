import styled from "styled-components";

export const Container = styled.div`
    .depoiments {
        width: 80%;
        margin: 0 auto;
        margin-top: 3%;
        margin-bottom: 3%;
    }

    .testimonial-carousel .owl-item .testimonial-item img {
        width: 60px;
        height: 60px;
    }

    .testimonial-carousel .owl-item .testimonial-item,
    .testimonial-carousel .owl-item .testimonial-item * {
        transition: 0.5s;
    }

    .testimonial-carousel .owl-item.center .testimonial-item {
        background: var(--primary) !important;
    }

    .testimonial-carousel .owl-item.center .testimonial-item * {
        color: #ffffff !important;
    }

    .testimonial-carousel .owl-nav {
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }

    .testimonial-carousel .owl-nav .owl-prev,
    .testimonial-carousel .owl-nav .owl-next {
        margin: 0 12px;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        font-size: 22px;
        color: var(--light);
        background: var(--primary);
        transition: 0.5s;
    }

    .testimonial-carousel .owl-nav .owl-prev:hover,
    .testimonial-carousel .owl-nav .owl-next:hover {
        color: var(--primary);
        background: var(--dark);
    }
    .estrelas input[type="radio"] {
        display: none;
    }
    .estrelas label i.fa {
        font-size: 2.5em;
    }
    .estrelas label i.fa:before {
        content: "\f005";
        color: #fc0;
    }
    .estrelas input[type="radio"]:checked ~ label i.fa:before {
        color: #ccc;
    }

    .subtextDepoiments {
        margin-top: 1%;
    }
    .textDepoiments {
        color: ${({ theme }) => theme.colors.primary};
        margin-bottom: 3%;
    }

    .indicatorsbottom {
        background-color: ${({ theme }) => theme.colors.primary};
    }

    .carousel-indicators {
        bottom: -50px;
    }

    .spacecard {
        @media only screen and (max-width: 600px) {
            margin-bottom: 4%;
        }
    }

    .card-body {
        background-color: ${({ theme }) => theme.colors.primary};
        padding: 3px;

        border-radius: 5px;
    }

    .internal-card {
        height: 320px;
    }
`;
