import React from "react";

import { Container } from "./styles";

const NotFound = () => (
    <Container>
        <h1>Not Found</h1>
    </Container>
);

export default NotFound;
