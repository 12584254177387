/* eslint-disable operator-linebreak */

import React, { useState } from "react";
import { BsStopwatch, BsWhatsapp } from "react-icons/bs";
import { MdOutlineAlternateEmail } from "react-icons/md";

import Toast from "../Toast";

import LogoLogicphire from "../../assets/images/logoLogicphire.webp";

import { Container } from "./styles";
import { SaveContact } from "../../controller/FirebaseController/Contact/createContactController";
import {
    validateEmail,
    validatePhoneNumber,
} from "../../controller/ValidateFileds/ValidateEmailController";

const ContactModal = () => {
    const [loadingContact, setLoadingContact] = useState(false);
    const [contactName, setContactName] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [contactMessage, setContactMessage] = useState("");

    const handleSubmitContact = async (event) => {
        event.preventDefault();

        if (
            contactName !== "" &&
            contactEmail !== "" &&
            contactPhone !== "" &&
            contactMessage !== ""
        ) {
            if (validateEmail(contactEmail)) {
                if (validatePhoneNumber(contactPhone)) {
                    setLoadingContact(true);

                    const result = await SaveContact(
                        contactName,
                        contactEmail,
                        contactPhone,
                        contactMessage
                    );

                    if (result) {
                        Toast(
                            "success",
                            "Tudo certo, a Logic agradesce seu contato e logo irá retorna-lo(a)!"
                        );

                        setContactName("");
                        setContactEmail("");
                        setContactPhone("");
                        setContactMessage("");
                    } else {
                        Toast(
                            "error",
                            "Ops, algo de errado não deu certo, favor tente novamente mais tarde!"
                        );
                    }

                    setLoadingContact(false);
                } else {
                    Toast("warning", "Campo de Telefone, incorreto!");
                }
            } else {
                Toast("warning", "Campo de Email, incorreto!");
            }
        } else {
            Toast("warning", "Todos os campos devem ser preenchidos!");
        }
    };

    return (
        <Container>
            <div
                className="modal fade"
                id="contactModal"
                tabIndex="-1"
                aria-labelledby="contactModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header headermodal">
                            <img
                                className="logomodal"
                                src={LogoLogicphire}
                                alt="Logicphire - logo"
                            />
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <form action="/action_page.php">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-4 bordermodalcontact">
                                        <div className="modalcontacttext">
                                            <div className="textscontact">
                                                <h6>
                                                    <BsStopwatch />
                                                    Horário de funcionamento:
                                                </h6>
                                                <p>
                                                    De segunda a sexta 09:00 as
                                                    17:00
                                                </p>
                                            </div>
                                            <div className="textscontact">
                                                <h6>
                                                    <MdOutlineAlternateEmail />
                                                    E-mail:
                                                </h6>
                                                <p>
                                                    logicphire@logicphire.com.br
                                                </p>
                                            </div>
                                            <div className="textscontact">
                                                <h6>
                                                    <BsWhatsapp />
                                                    Telefone:
                                                </h6>
                                                <p>(85) 99793-5406</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div className="mb-3 mt-3">
                                                    <label
                                                        htmlFor="text"
                                                        className="form-label"
                                                    >
                                                        Nome:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="nome"
                                                        placeholder="Nome"
                                                        name="nome"
                                                        required
                                                        value={contactName}
                                                        onChange={(event) =>
                                                            setContactName(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="mb-3 mt-3">
                                                    <label
                                                        htmlFor="email"
                                                        className="form-label"
                                                    >
                                                        Email:
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="email"
                                                        placeholder="nome@exemplo.com"
                                                        name="email"
                                                        required
                                                        value={contactEmail}
                                                        onChange={(event) =>
                                                            setContactEmail(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="mb-3 mt-3">
                                                    <label
                                                        htmlFor="text"
                                                        className="form-label"
                                                    >
                                                        Telefone:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="telefone"
                                                        placeholder="(99) 99999-9999"
                                                        name="telefone"
                                                        required
                                                        value={contactPhone}
                                                        onChange={(event) =>
                                                            setContactPhone(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 mt-3">
                                            <label
                                                htmlFor="text"
                                                className="form-label"
                                            >
                                                Mensagem
                                            </label>
                                            <div className="form-floating">
                                                <textarea
                                                    className="form-control"
                                                    id="comment"
                                                    name="text"
                                                    rows="5"
                                                    required
                                                    value={contactMessage}
                                                    onChange={(event) =>
                                                        setContactMessage(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="submit"
                                    className="btn btn-primary buttonSend"
                                    onClick={handleSubmitContact}
                                >
                                    {loadingContact ? (
                                        <div
                                            className="spinner-border"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </div>
                                    ) : (
                                        "Enviar"
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default ContactModal;
