import { collection, getDocs } from "firebase/firestore";

import { firestore } from "../../../services/Firebase";

const CollectionName = "projects";

export const getAllPorjects = async () => {
    try {
        const collectionRef = collection(firestore, CollectionName);
        const querySnapshot = await getDocs(collectionRef);

        const dataProjects = [];

        querySnapshot.forEach((dataDocument) => {
            dataProjects.push(dataDocument.data());
        });

        return dataProjects;
    } catch (error) {
        console.log(error);

        return [];
    }
};
