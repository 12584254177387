import styled from "styled-components";

export const Container = styled.div`
    @media (min-width: 576px) {
        max-width: 540px;
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: 768px) {
        max-width: 720px;
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: 992px) {
        max-width: 960px;
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: 1200px) {
        max-width: 1600px;
        margin: 0 auto;
        text-align: center;
    }
`;

export const Menu = styled.div`
    background-color: ${(props) => props.theme.colors.primary};
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding-top: 1%;
`;

export const Terms = styled.div`
    width: 80%;
    text-align: justify;
    margin: 0 auto;
    padding-top: 2%;
    padding-bottom: 2%;

    h4 {
        margin-top: 2%;
    }

    h5 {
        margin-top: 2%;
    }
`;
